import './style.css';
import {
  Map,
  View
} from 'ol';
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer
} from 'ol/layer.js';
import LayerGroup from 'ol/layer/Group.js';
import Point from 'ol/geom/Point.js';
import {
  OSM,
  Vector as VectorSource
} from 'ol/source.js';
import {
  transform,
  fromLonLat,
  get as getProjection
} from 'ol/proj.js';
import WMTS from 'ol/source/WMTS.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import {
  getWidth,
  getTopLeft
} from 'ol/extent.js';
import Feature from 'ol/Feature.js';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style
} from 'ol/style.js';
import Geolocation from 'ol/Geolocation.js';
import {
  setTheme
} from "@ui5/webcomponents-base/dist/config/Theme.js";
import {
  Select,
  Translate,
  defaults as defaultInteractions,
} from 'ol/interaction.js';

import "@ui5/webcomponents/dist/Assets.js";
import "@ui5/webcomponents-fiori/dist/Assets.js";
import "@ui5/webcomponents-icons/dist/Assets.js";

import "@ui5/webcomponents/dist/Button.js";
import "@ui5/webcomponents/dist/CheckBox";
import "@ui5/webcomponents/dist/Label.js";
import "@ui5/webcomponents/dist/List.js";
import "@ui5/webcomponents/dist/Slider.js";
import "@ui5/webcomponents/dist/StandardListItem.js";
import "@ui5/webcomponents/dist/Table.js";
import "@ui5/webcomponents/dist/TableColumn.js";
import "@ui5/webcomponents/dist/TableRow.js";
import "@ui5/webcomponents/dist/TableCell.js";
import "@ui5/webcomponents/dist/TableGroupRow.js";
import "@ui5/webcomponents/dist/Link";
import "@ui5/webcomponents/dist/Badge";
import "@ui5/webcomponents/dist/BusyIndicator";
import "@ui5/webcomponents/dist/RangeSlider";
import "@ui5/webcomponents/dist/DateRangePicker.js";
import "@ui5/webcomponents/dist/Panel";
import "@ui5/webcomponents/dist/Card";
import "@ui5/webcomponents/dist/CardHeader.js";
import "@ui5/webcomponents/dist/ComboBox";
import "@ui5/webcomponents/dist/Toast";
import "@ui5/webcomponents/dist/Input.js";
import "@ui5/webcomponents/dist/RadioButton";
import "@ui5/webcomponents/dist/Menu.js";
import "@ui5/webcomponents/dist/MenuItem.js";
import "@ui5/webcomponents-fiori/dist/ShellBar";
import "@ui5/webcomponents-fiori/dist/ShellBarItem";
import "@ui5/webcomponents-fiori/dist/Page.js";
import "@ui5/webcomponents-fiori/dist/Bar.js";
import "@ui5/webcomponents-icons/dist/AllIcons.js";

//setTheme("sap_horizon");

var geoserverPublicHost;
var geoserverWorkspace;
var isAuthorizedCreateRoute = false;
var isAuthorizedShowReport = false;

const hideMapIfNotLoggedOn = false;
const activitiesLayerName = 'v_myway_activity';
const activitiesLayerStyle = 'black_white';
const activitiesHighlightLayerStyle = 'black_white_highlight';
const surfaceActivityLayerName = 'v_myway_activity_surface';
const distanceActivityLayerName = 'v_myway_activity_distance';

const distanceLayerStyle = 'distance';

const routesLayerName = 'v_myway_route';
const routesLayerStyle = 'black_white';
const routesHighlightLayerStyle = 'black_white_highlight';
const routeEditLayerStyle = 'route_black_white_edit';
const surfaceRouteLayerName = 'v_myway_route_surface';
const distanceRouteLayerName = 'v_myway_route_distance';


const mtbLayerName = 'mv_osm_route_mtb';
const mtbExtent = [-8.793446619494853, 36.00851074041118, 14.82490723987141, 54.43502687696797]; // kan je ook halen uit de capabilities; is eigenlijk afh. van layer! Hebben nu deze overgetypt van mv_osm_myway_gravel
const previewLayerName = 'mv_osm_myway_gravel';
const previewLayerFilter = "intersects(geom, collectGeometries(queryCollection('Refgem', 'the_geom', 'NAAM = ''Herentals'' or NAAM = ''Olen'' or NAAM = ''Herenthout'' or NAAM = ''Vorselaar'' or NAAM = ''Lille'' or NAAM = ''Grobbendonk''')))";
const previewExtent = [-9.273812857419031, 36.00948811073648, 15.032631420382996, 55.02933746552027];
const grassLayerName = 'mv_osm_myway_grass';
const grassExtent = [-9.112415357556541, 36.28579559844267, 15.056737711032342, 54.96095263157889];
const unpavedLayerName = 'mv_osm_myway_unpaved';
const unpavedExtent = [-9.298335180334337, 35.264441429808585, 15.097350545027389, 55.05659600308625];

const activitiesLayerInitialVisibility = true;
const routesLayerInitialVisibility = false;

const accuracyFeature = new Feature();

const positionFeature = new Feature();
positionFeature.setStyle(
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  })
);

const cFeature = new Feature();
cFeature.setStyle(
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#ccdde6',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  })
);

const crpFeature = new Feature();
crpFeature.setStyle(
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  })
);

//import { setTheme, getDefaultTheme  } from "@ui5/webcomponents-base/dist/config/Theme.js";
//setTheme("sap_belize_hcb");

window.onloadBody = onloadBody;

window.filterActivitiesOnStravaId = filterActivitiesOnStravaId;
window.filterRoutesOnStravaId = filterRoutesOnStravaId;
window.filterRoutesOnId = filterRoutesOnId;
window.login = login;

var editAnyRouteTimestamp = new Date().getTime();

var activitiesLayer;
var highlightActivityLayer;
var distanceActivityLayer;
var surfaceActivityLayer;
var routesLayer;
var highlightRouteLayer;
var distanceRouteLayer;
var surfaceRouteLayer;
var g_dMin;
var g_dMax;
var g_layerFeaturesFetches = [];

var selectedRouteLayersSetInVisibleTimeout;
var selectedActivityLayersSetInVisibleTimeout;

var isEditingRoute = false;
var isAddingPoint = false;
var isRoutePointSelected = false;
var isMovingRoutePoint = false;

var editRouteId = null;
var editRouteUndos = [];

var editRoutePointerMovePostAsyncBusy = false;
var pointerMoveToProcess = null;

const osmLayerTitle = 'OSM';
const baseLayerGroupTitle = 'Base Maps';
const userLayerGroupTitle = 'My Layers';
const initialLonLat = [4.842864351210061, 51.17152749317524]; //Herentals

const shellbar = document.getElementById("shellbar");
const shellbarMenu = document.getElementById("shellbarMenu");
const avatar = document.getElementById("avatar");

const popoverAvatar = document.getElementById("popoverAvatar");
const listAvatar = document.getElementById("listAvatar");
const liConnectStrava = document.getElementById("liConnectStrava");
//const liLogIn = document.getElementById("liLogIn");
const liLogOut = document.getElementById("liLogOut");

const popoverMenu = document.getElementById("popoverMenu");
const listMenu = document.getElementById("listMenu");
const liFindMyLocation = document.getElementById("liFindMyLocation");
const liLayerSwitcher = document.getElementById("liLayerSwitcher");
const liActivityFilter = document.getElementById("liActivityFilter");
const liRouteFilter = document.getElementById("liRouteFilter");
const liUploadStravaRoute = document.getElementById("liUploadStravaRoute");
const liCreateRoute = document.getElementById("liCreateRoute");
const liReport = document.getElementById("liReport");
const liBacklog = document.getElementById("liBacklog");

const popoverEditRoute = document.getElementById("popoverEditRoute");
const listEditRoute = document.getElementById("listEditRoute");
const liEditRouteUndo = document.getElementById("liEditRouteUndo");
const liEditRouteSave = document.getElementById("liEditRouteSave");

const popoverEditRoutePoint = document.getElementById("popoverEditRoutePoint");
const rbEditRoutePointRoutingModeA = document.getElementById("rbEditRoutePointRoutingModeA");
const rbEditRoutePointRoutingModeM = document.getElementById("rbEditRoutePointRoutingModeM");
const rbEditRoutePointRoutingModeG = document.getElementById("rbEditRoutePointRoutingModeG");
const buttonEditRoutePointDelete = document.getElementById("buttonEditRoutePointDelete");
const buttonEditRoutePointCancel = document.getElementById("buttonEditRoutePointCancel");

const popoverFindMyLocation = document.getElementById("popoverFindMyLocation");
const listFindMyLocation = document.getElementById("listFindMyLocation");
const liFindMyLocationExit = document.getElementById("liFindMyLocationExit");


const popoverLayerSwitcher = document.getElementById("popoverLayerSwitcher");
const tableLayerSwitcher = document.getElementById("tableLayerSwitcher");

const popoverActivityFilter = document.getElementById("popoverActivityFilter");
const buttonDateFilterToday = document.getElementById("buttonDateFilterToday");
const buttonDateFilterLastWeek = document.getElementById("buttonDateFilterLastWeek");
const buttonDateFilterLastMonth = document.getElementById("buttonDateFilterLastMonth");
const buttonDateFilterLastYear = document.getElementById("buttonDateFilterLastYear");
const buttonDateFilterAll = document.getElementById("buttonDateFilterAll");
const activitiesRangeSlider = document.getElementById("activitiesRangeSlider");
const activitiesDateRangePicker = document.getElementById("activitiesDateRangePicker");
const comboboxSportType = document.getElementById("comboboxSportType");
const inputActivityStravaId = document.getElementById("inputActivityStravaId");

const popoverRouteFilter = document.getElementById("popoverRouteFilter");
const inputFilterRouteId = document.getElementById("inputFilterRouteId");
const inputFilterRouteStravaId = document.getElementById("inputFilterRouteStravaId");

const popoverEditRouteSave = document.getElementById("popoverEditRouteSave");
const inputEditRouteSaveName = document.getElementById("inputEditRouteSaveName");
const buttonEditRouteSave = document.getElementById("buttonEditRouteSave");

const popoverBacklog = document.getElementById("popoverBacklog");
const tableBacklog = document.getElementById("tableBacklog");

const popoverUploadRoute = document.getElementById("popoverUploadRoute");
const rbUploadRouteLast = document.getElementById("rbUploadRouteLast");
const rbUploadRouteStravaId = document.getElementById("rbUploadRouteStravaId");
const inputUploadRouteStravaId = document.getElementById("inputUploadRouteStravaId");
const buttonUploadRoute = document.getElementById("buttonUploadRoute");

const popoverFeature = document.getElementById("popoverFeature");
const popoverAnchor = document.getElementById("popoverAnchor");

const toast = document.getElementById("toast");

const e_map = document.getElementById("map");
const busyIndicator = document.getElementById("busyIndicator");

const projection = getProjection('EPSG:4326');
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / 256;
const gridNames = ['EPSG:4326:0', 'EPSG:4326:1', 'EPSG:4326:2', 'EPSG:4326:3', 'EPSG:4326:4', 'EPSG:4326:5', 'EPSG:4326:6', 'EPSG:4326:7', 'EPSG:4326:8', 'EPSG:4326:9', 'EPSG:4326:10', 'EPSG:4326:11', 'EPSG:4326:12', 'EPSG:4326:13', 'EPSG:4326:14', 'EPSG:4326:15', 'EPSG:4326:16', 'EPSG:4326:17', 'EPSG:4326:18', 'EPSG:4326:19', 'EPSG:4326:20', 'EPSG:4326:21'];
const resolutions = new Array(22);
for (let z = 0; z < 22; ++z) {
  resolutions[z] = size / Math.pow(2, z + 1);
}

var oModel = {
  athlete: null
}

window.addEventListener(
  "resize",
  function (event) {
    fitMapToFullscreen();
  }
);

shellbar.accessibilityTexts = {
  logoTitle: "Gravelenzo"
};
shellbar.addEventListener(
  "ui5-profile-click",
  function (event) {
    //console.log(event.detail.targetRef);
    popoverAvatar.showAt(event.detail.targetRef, true);
  }
);

shellbar.addEventListener(
  "logo-click",
  function (event) {
    window.location.href = window.location.origin;
    //window.open("https://www.strava.com/dashboard");
  }
);

shellbarMenu.addEventListener(
  "click",
  function (event) {
    var cvalue = getCookie("auth");
    if (cvalue == 1) {
      if (isEditingRoute) {
        popoverEditRoute.showAt(event.detail.targetRef, true);
      } else {
        if (geolocation.getTracking()) {
          popoverFindMyLocation.showAt(event.detail.targetRef, true);
        } else {
          popoverMenu.showAt(event.detail.targetRef, true);
        }
      }
    } else {
      toast.innerHTML = "Please log in first...";
      toast.show();
    }
  }
);

listAvatar.addEventListener(
  "item-click",
  function (event) {
    switch (event.detail.item.id) {
/*
      case "liLogIn":
        login();
        break;
*/
      case "liLogOut":
        popoverAvatar.close();
        document.cookie = "auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        sessionStorage.removeItem("token"); // kan je eventueel weglaten; dan zie je wel geen login registratie op db, maar vermijd je een refersh van het scherm...

        window.location.href = window.location.origin;
        //checkUserLoggedIn();
        break;
    }
  }
);

function login() {
  popoverAvatar.close();
  setCookie('auth', '1', 30);
  checkUserLoggedIn();
}

listMenu.addEventListener(
  "item-click",
  function (event) {
    switch (event.detail.item.id) {
      case "liFindMyLocation":
        popoverMenu.close();
        findMyLocation();
        break;
      case "liLayerSwitcher":
        popoverLayerSwitcher.showAt(document.getElementById(event.detail.item.id), true);
        break;
      case "liUploadStravaRoute":
        popoverUploadRoute.showAt(document.getElementById(event.detail.item.id), true);
        break;
      case "liCreateRoute":
        createRoute();
        break;
      case "liActivityFilter":
        popoverActivityFilter.showAt(document.getElementById(event.detail.item.id), true);
        break;
      case "liRouteFilter":
        popoverRouteFilter.showAt(document.getElementById(event.detail.item.id), false);
        break;
      case "liBacklog":
        refreshAndShowBacklog(document.getElementById(event.detail.item.id), true);
        break;
        case "liReport":
          showReport();
          break;
      }
  }
);

listEditRoute.addEventListener(
  "item-click",
  function (event) {
    switch (event.detail.item.id) {
      case "liEditRouteUndo":
        editRouteUndo();
        break;
      case "liEditRouteSave":
        popoverEditRouteSave.showAt(document.getElementById(event.detail.item.id), false);
        //editRouteSave();
        break;
    }
  }
);

rbEditRoutePointRoutingModeA.addEventListener(
  "change",
  function (event) {
    editRoutePointRoutingModeChanged();
  }
);
rbEditRoutePointRoutingModeM.addEventListener(
  "change",
  function (event) {
    editRoutePointRoutingModeChanged();
  }
);
rbEditRoutePointRoutingModeG.addEventListener(
  "change",
  function (event) {
    editRoutePointRoutingModeChanged();
  }
);

buttonEditRoutePointDelete.addEventListener(
  "click",
  function (event) {
    editRoutePointDelete();
  }
);
buttonEditRoutePointCancel.addEventListener(
  "click",
  function (event) {
    editRoutePointCancel();
  }
);

listFindMyLocation.addEventListener(
  "item-click",
  function (event) {
    switch (event.detail.item.id) {
      case "liFindMyLocationExit":
        findMyLocationExit();
        break;
    }
  }
);

activitiesRangeSlider.addEventListener(
  "input",
  function (event) {
    var startDate = startOfDay(new Date(parseInt(activitiesRangeSlider.getAttribute("start-value")) * 1000));
    var endDate = endOfDay(new Date(parseInt(activitiesRangeSlider.getAttribute("end-value")) * 1000));

    setDateFilter("custom", startDate, endDate, this);
  }
);

activitiesDateRangePicker.addEventListener(
  "change",
  function (event) {
    console.log(activitiesDateRangePicker.getAttribute("value"));
    var splits = activitiesDateRangePicker.getAttribute("value").split(" - ");

    var startDate = startOfDay(getDateFromNormal(splits[0]));
    var endDate = endOfDay(getDateFromNormal(splits[1]));

    setDateFilter("custom", startDate, endDate, this);

    //activitiesRangeSlider.setAttribute("start-value", startDate.getTime() / 1000);
    //activitiesRangeSlider.setAttribute("end-value", endDate.getTime() / 1000);

    //updateLayer(activitiesLayer, null, null, startDate, endDate);

  }
);

buttonDateFilterToday.addEventListener(
  "click",
  function (event) {
    setDateFilter("today", null, null, this);
  }
);
buttonDateFilterLastWeek.addEventListener(
  "click",
  function (event) {
    setDateFilter("lastWeek", null, null, this);
  }
);
buttonDateFilterLastMonth.addEventListener(
  "click",
  function (event) {
    setDateFilter("lastMonth", null, null, this);
  }
);
buttonDateFilterLastYear.addEventListener(
  "click",
  function (event) {
    setDateFilter("lastYear", null, null, this);
  }
);
buttonDateFilterAll.addEventListener(
  "click",
  function (event) {
    setDateFilter("all", null, null, this);
  }
);

inputUploadRouteStravaId.showClearIcon = true;
inputUploadRouteStravaId.addEventListener(
  "input",
  function (event) {
    rbUploadRouteStravaId.checked = true;

  }
);
buttonUploadRoute.addEventListener(
  "click",
  function (event) {
    if (rbUploadRouteLast.checked) {
      uploadLastRoute();
    } else {
      uploadRoute(inputUploadRouteStravaId.getAttribute("value"));
    }
  }
);

inputEditRouteSaveName.showClearIcon = true;
inputEditRouteSaveName.addEventListener(
  "change",
  function (event) {
    //console.log(event);

  }
);
buttonEditRouteSave.addEventListener(
  "click",
  function (event) {
    editRouteSave(inputEditRouteSaveName.getAttribute("value"));
  }
);

comboboxSportType.addEventListener(
  "selection-change",
  function (event) {
    updateLayer(activitiesLayer, null, null, null, null, comboboxSportType.getAttribute("value"), null);
  }
);

inputActivityStravaId.showClearIcon = true;
inputActivityStravaId.addEventListener(
  "input",
  function (event) {
    hideSelectedActivityLayers();
    updateLayer(activitiesLayer, null, null, null, null, null, inputActivityStravaId.getAttribute("value"));
  }
);

inputFilterRouteStravaId.showClearIcon = true;
inputFilterRouteStravaId.addEventListener(
  "input",
  function (event) {
    hideSelectedRouteLayers();
    updateLayer(routesLayer, null, null, null, null, null, inputFilterRouteStravaId.getAttribute("value"));
  }
);
inputFilterRouteId.showClearIcon = true;
inputFilterRouteId.addEventListener(
  "input",
  function (event) {
    hideSelectedRouteLayers();
    updateLayer(routesLayer, null, inputFilterRouteId.getAttribute("value"), null, null, null, null);
  }
);


var token = getQueryParam("token");
if (token != null) {
  console.log("token: " + token);
  sessionStorage.setItem("token", token);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.delete("token", token);

  window.location.href = window.location.origin + (params == "" ? "" : "?" + params);
} else {
  console.log("token: " + sessionStorage.getItem("token"));
}

var queryParam_activity_strava_id = getQueryParam("activity_strava_id");

const view = new View({
  //center: [0, 10],
  center: transform(initialLonLat, 'EPSG:4326', 'EPSG:3857'),
  zoom: 12,
});

const translate = new Translate({
  filter: function (feature, layer) {
    return (feature == crpFeature);
  },
});
translate.on('translatestart', evt => {
  console.log("translatestart");
  crpFeature.moveStartCoordinates = crpFeature.getGeometry().getCoordinates();
  isMovingRoutePoint = true;


  evt.features.forEach(feat => {
    // process every feature
  })
})
translate.on('translateend', evt => {
  console.log("translateend");

  // sometime, crpFeature.getGeometry() is null!!
  if (crpFeature.getGeometry() != null) {
    if (crpFeature.moveStartCoordinates.toString() == crpFeature.getGeometry().getCoordinates().toString()) {
      // Not a moved... A click event will also happen after the translateend
      isMovingRoutePoint = false;
    } else {
      console.log("Point " + crpFeature.iOnLine + " moved from " + crpFeature.moveStartCoordinates + " to " + crpFeature.getGeometry().getCoordinates());
      editRouteClickOrMoveEnd(crpFeature.getGeometry().getCoordinates(), view.getProjection().getCode(), evt.pixel);
    }
  } else {
    console.log("Something strange happened");
    isMovingRoutePoint = false;
  }

  //isMovingRoutePoint = false;

  evt.features.forEach(feat => {
    // process every feature
  })
})


const map = new Map({
  interactions: defaultInteractions().extend([translate]),
  target: 'map',
  layers: [],
  view: view,
  moveTolerance: 5
});
map.on('singleclick', function (evt) {
  //console.log("Zoom: " + map.getView().getZoom());
  stopFindMyLocation();
  switch (true) {
    case isEditingRoute:
      console.log("click");
      editRouteClickOrMoveEnd(evt.coordinate, view.getProjection().getCode(), evt.pixel);
      break;
    default:
      showFeaturesOnCoordinateOrExtent(evt.coordinate, null, view.getProjection().getCode(), evt.pixel);
  }
});
map.on('movestart', function (evt) {
  //if (popoverFeature.isOpen()) {
  if (popoverFeature.open) {
    popoverFeature.close();
  }

});
map.on('pointermove', function (evt) {
  if (isEditingRoute) {
    editRoutePointerMove(evt.coordinate, view.getProjection().getCode());
  }
});


const geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: view.getProjection(),
});
geolocation.on('change:position', function () {
  const coordinates = geolocation.getPosition();
  //console.log(coordinates);
  positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);

  //view.animate({ center: coordinates }, { zoom: (view.getZoom() < 12 ? 12 : view.getZoom()) });
  
  //Animation zorgt ervoor dat de positionFeature niet kan volgen...
  //view.animate({ center: coordinates });
  view.setCenter(coordinates);

});
geolocation.on('change:accuracyGeometry', function () {
  accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});
/*
geolocation.on('change:tracking', function () {
  
});
*/

new ResizeSensor(document.getElementById("map"), function () {
  setTimeout(function () { map.updateSize(); }, 200);
});

const baseLayerGroup = new LayerGroup({
  title: baseLayerGroupTitle,
  layers: []
});

// OSM
const osmLayer = new TileLayer({
  title: osmLayerTitle,
  visible: true,
  source: new OSM(),
});
osmLayer.setOpacity(0.5);
baseLayerGroup.getLayers().push(osmLayer);
map.getLayers().push(baseLayerGroup);

var previewLayer; 

addLayers();

function addConfiglayer(layer) {
  switch(layer.layer_type) {
    case 'wms':
      addWmsLayer(layer.layers, layer.styles, layer.cql_filter, layer.layergroup_title, layer.layer_title, layer.visible, layer.min_zoom, null, false, false);
      break;
    case 'wmts':
      //todo: loopen over layers, en geoserverWorkspace toevoegen; idem voor style?
      addWmtsLayer(geoserverWorkspace + ':' + layer.layers, layer.styles, [layer.minx, layer.miny, layer.maxx, layer.maxy], layer.layergroup_title, layer.layer_title, layer.visible, false, false); // seed killed
      break;
    default:
  } 
}

function resetLayers() {
  map.getLayers().forEach(function (layerGroup, iLayerGroup) {
    //console.log(layerGroup.getProperties().title);

    layerGroup.getLayers().forEach(function (layer) {
      //console.log(layer.getProperties().title);
      if (layer == osmLayer || layer == previewLayer) {
        layer.setVisible(true);
      } else {
        layer.setVisible(false);
      }

    });

  });
}

function showReport() {
  //window.open("/report/?table=v_athlete_ratelimitstats&fields=firstname,lastname,webhook_rate_unprocessed,strava_rate_today");
  window.open("/report/?table=v_athlete_stats");
}

function addLayers() {
  getMyConfigAsync(null, function (dummy, err, myConfig) {
    if (err) {
    } else {
      if (myConfig != null) {
        geoserverPublicHost = myConfig.geoserverPublicHost;
        geoserverWorkspace = myConfig.geoserverWorkspace;

        //previewLayer = addWmtsLayer(geoserverWorkspace + ':' + previewLayerName, null, previewExtent, 'Preview', 'Preview', false, false, true);
        previewLayer = addWmsLayer(previewLayerName, null, previewLayerFilter, 'Preview', 'Preview', false, null, null, false, true);

        getAthleteDetailByTokenAsync(sessionStorage.getItem("token"), function (dummy, err, athleteDetail) {
          if (err) {
          } else {
            if (athleteDetail != null) {
              //console.log(athleteDetail);
              isAuthorizedCreateRoute = athleteDetail.config.isAuthorizedCreateRoute;
              isAuthorizedShowReport = athleteDetail.config.isAdmin;
              
              //Configured bottom layers
              //addWmtsLayer(geoserverWorkspace + ':' + mtbLayerName, null, mtbExtent, 'Routes', 'MTB', false, false, false); // seed killed
              if (athleteDetail.bottomLayers != null) {
                athleteDetail.bottomLayers.forEach(addConfiglayer);
              }
      
              //userlayers bottom (route, activity)
              addUserLayersBottom();

              /*
              //surface layers
              addWmtsLayer(geoserverWorkspace + ':' + gravelLayerName, null, gravelExtent, 'Surface', 'Gravel', true, false, false);
              addWmtsLayer(geoserverWorkspace + ':' + grassLayerName, null, grassExtent, 'Surface', 'Grass', false, false, false);
              addWmtsLayer(geoserverWorkspace + ':' + unpavedLayerName, null, unpavedExtent, 'Surface', 'Dirt', false, false, false);
              */

              //Configured top layers
              if (athleteDetail.topLayers != null) {
                athleteDetail.topLayers.forEach(addConfiglayer);
              }

            }
      
          }
      
          // do remaining
          addUserLayersTop();
      
          createLayerSwitcher();
      
          new VectorLayer({
            map: map,
            source: new VectorSource({
              features: [accuracyFeature, positionFeature, cFeature, crpFeature],
            }),
          });
      
          checkUserLoggedIn();
      
      
        }, null)
            
      }

    }

  }, null)
  
}

function addUserLayersBottom() {
  // These layers should contain athlete_id filter

  var startDate = new Date();
  var endDate = startDate;

  activitiesLayer = addWmsLayer(activitiesLayerName, activitiesLayerStyle, "athlete_id='" + "0" + "'" + " and " + "start_date_local between '" + formatDateTimeToUTC(startDate) + "' and '" + formatDateTimeToUTC(endDate) + "'", userLayerGroupTitle, 'Activities', activitiesLayerInitialVisibility, null, null, true, false);
  routesLayer = addWmsLayer(routesLayerName, routesLayerStyle, "athlete_id='" + "0" + "'", userLayerGroupTitle, 'Routes', routesLayerInitialVisibility, null, null, true, false);

}

function addUserLayersTop() {
  // These layers shoul contain athlete_id filter

  highlightRouteLayer = addWmsLayer(routesLayerName, routesHighlightLayerStyle, "athlete_id='0' and id='0'", 'Top Layers', '', false, null, null, true, true);
  surfaceRouteLayer = addWmsLayer(surfaceRouteLayerName, null, "athlete_id='0' and id='0'", 'Top Layers', '', false, null, null, true, true);
  distanceRouteLayer = addWmsLayer(distanceRouteLayerName, distanceLayerStyle, "athlete_id='0' and id='0'", 'Top Layers', '', false, null, null, true, true);

  highlightActivityLayer = addWmsLayer(activitiesLayerName, activitiesHighlightLayerStyle, "athlete_id='0' and id='0'", 'Top Layers', '', false, null, null, true, true);
  surfaceActivityLayer = addWmsLayer(surfaceActivityLayerName, null, "athlete_id='0' and id='0'", 'Top Layers', '', false, null, null, true, true);
  distanceActivityLayer = addWmsLayer(distanceActivityLayerName, distanceLayerStyle, "athlete_id='0' and id='0'", 'Top Layers', '', false, null, null, true, true);
}

function createLayerSwitcher() {

  map.getLayers().forEach(function (layerGroup, iLayerGroup) {
    var containsNonExcludedLayers = false;
    layerGroup.getLayers().forEach(function (layer) {
      if (!layer.excludeFromSwitcherLayer) {
        containsNonExcludedLayers = true;
      }

    });

    if (containsNonExcludedLayers) {

      var ui5TableGroupRow = document.createElement("ui5-table-group-row");
      var textNode = document.createTextNode(layerGroup.getProperties().title);
      ui5TableGroupRow.appendChild(textNode);
      tableLayerSwitcher.appendChild(ui5TableGroupRow);

      layerGroup.getLayers().forEach(function (layer, iLayer) {
        if (!layer.excludeFromSwitcherLayer) {

          var ui5TableRow = document.createElement("ui5-table-row");
          ui5TableRow.setAttribute("id", "tableLayerSwitcher_" + iLayerGroup + "_" + iLayer);
          tableLayerSwitcher.appendChild(ui5TableRow);

          var ui5TableCell1 = document.createElement("ui5-table-cell");
          ui5TableRow.appendChild(ui5TableCell1);
          var ui5Checkbox = document.createElement("ui5-checkbox");
          ui5Checkbox.setAttribute("text", layer.getProperties().title);
          if (layer.getVisible()) {
            ui5Checkbox.setAttribute("checked", "true");
          }
          ui5TableCell1.appendChild(ui5Checkbox);

          var ui5TableCell2 = document.createElement("ui5-table-cell");
          ui5TableRow.appendChild(ui5TableCell2);
          var ui5Slider = document.createElement("ui5-slider");
          ui5Slider.setAttribute("min", "0");
          ui5Slider.setAttribute("max", "1");
          ui5Slider.setAttribute("step", "0.01");
          ui5Slider.setAttribute("value", (layerGroup.getProperties().title == baseLayerGroupTitle && layer.getProperties().title == osmLayerTitle ? "0.5" : "1"));
          ui5TableCell2.appendChild(ui5Slider);

          ui5Checkbox.addEventListener(
            "change",
            function (event) {
              var layer = getLayerByIndices(event.target.parentElement.parentElement.id.split("_")[1], event.target.parentElement.parentElement.id.split("_")[2]);
              layer.setVisible(event.target.checked);

              switch (layer) {
                case activitiesLayer:
                  //selectedActivityLayersSetVisible(event.target.checked);

                  liActivityFilter.style.display = (event.target.checked ? "block" : "none");
                  break;
                case routesLayer:
                  //selectedRouteLayersSetVisible(event.target.checked);

                  liRouteFilter.style.display = (event.target.checked ? "block" : "none");
                  liUploadStravaRoute.style.display = (event.target.checked ? "block" : "none");
                  liCreateRoute.style.display = (event.target.checked && isAuthorizedCreateRoute ? "block" : "none");
                  break;
              }

            }
          );

          ui5Slider.addEventListener(
            "input",
            function (event) {
              var layer = getLayerByIndices(event.target.parentElement.parentElement.id.split("_")[1], event.target.parentElement.parentElement.id.split("_")[2]);
              layer.setOpacity(parseFloat(event.target.value));
              if (layer == activitiesLayer) {
                highlightActivityLayer.setOpacity(parseFloat(event.target.value));
                distanceActivityLayer.setOpacity(parseFloat(event.target.value));
                surfaceActivityLayer.setOpacity(parseFloat(event.target.value));
              }
              if (layer == routesLayer) {
                highlightRouteLayer.setOpacity(parseFloat(event.target.value));
                distanceRouteLayer.setOpacity(parseFloat(event.target.value));
                surfaceRouteLayer.setOpacity(parseFloat(event.target.value));
              }
            }
          );

        }
      });
    }


  });
}

function getLayerByTitles(layerGroupTitle, layerTitle) {
  var theLayer = null;

  map.getLayers().forEach(function (layerGroup) {
    if (layerGroup.getProperties().title == layerGroupTitle) {
      layerGroup.getLayers().forEach(function (layer) {
        if (layer.getProperties().title == layerTitle) {
          theLayer = layer;
        };
      });
    }
  });

  return theLayer;
}

function getLayerByIndices(iLayerGroup, iLayer) {
  var theLayer = null;

  map.getLayers().forEach(function (layerGroup, i1) {
    if (i1 == iLayerGroup) {
      layerGroup.getLayers().forEach(function (layer, i2) {
        if (i2 == iLayer) {
          theLayer = layer;
        };
      });
    }
  });

  return theLayer;

}

function addWmtsLayer(layer, style, extent, layerGroupTitle, title, visible, isUserLayer, excludeFromSwitcherLayer) {
  var theLayerGroup;
  var layerGroups = map.getLayers();

  layerGroups.forEach(function (layerGroup) {
    if (layerGroup.getProperties().title == layerGroupTitle) {
      theLayerGroup = layerGroup;
    }
  });
  if (theLayerGroup == null) {
    theLayerGroup = new LayerGroup({
      title: layerGroupTitle,
      layers: []
    });
    layerGroups.push(theLayerGroup);
  }

  const tileLayer = new TileLayer({
    title: title,
    visible: visible, // er wordt geen rekening gehouden met deze waarde???? De waarde van de layergroup wordt overgenomen???
    source: new WMTS({
      url: 'https://' + geoserverPublicHost + '/geoserver/gwc/service/wmts',
      layer: layer,
      matrixSet: 'EPSG:4326',
      format: 'image/png',
      projection: projection,
      tileGrid: new WMTSTileGrid({
        tileSize: [256, 256],
        //extent: [-180.0, -90.0, 180.0, 90.0], // TileOutOfRange exception indien je wmts niet voor volledige range een tile teruggeeft...
        extent: extent,
        origin: [-180.0, 90.0],
        resolutions: resolutions,
        matrixIds: gridNames
      }),
      style: (style == null ? '' : style),
      wrapX: true,
    }),
  });
  tileLayer.getSource().setTileLoadFunction(function (tile, src) {
    tile.getImage().src = src + '&token=' + sessionStorage.getItem("token");
  });


  tileLayer.isUserLayer = isUserLayer;
  tileLayer.excludeFromSwitcherLayer = excludeFromSwitcherLayer;

  theLayerGroup.getLayers().push(tileLayer);

  return tileLayer;

}

function addWmsLayer(wmslayers, styles, cql_filter, layerGroupTitle, layerTitle, visible, minZoom, maxZoom, isUserLayer, excludeFromSwitcherLayer) {
  var theLayerGroup;
  var layerGroups = map.getLayers();
  layerGroups.forEach(function (layerGroup) {
    if (layerGroup.getProperties().title == layerGroupTitle) {
      theLayerGroup = layerGroup;
    }
  });
  if (theLayerGroup == null) {
    theLayerGroup = new LayerGroup({
      title: layerGroupTitle,
      layers: []
    });
    layerGroups.push(theLayerGroup);
  }

  var params;

  if (cql_filter == null) {
    if (styles == null) {
      params = { "LAYERS": wmslayers };
    } else {
      params = { "LAYERS": wmslayers, "STYLES": styles };
    }
  } else {
    if (styles == null) {
      params = { "LAYERS": wmslayers, "cql_filter": cql_filter };
    } else {
      params = { "LAYERS": wmslayers, "STYLES": styles, "cql_filter": cql_filter };
    }
  }
  //var params = (cql_filter == null ? {"LAYERS": wmslayers} : {"LAYERS": wmslayers, "cql_filter" : cql_filter});

  var theLayer = new ImageLayer({
    title: layerTitle,
    visible: visible,
    source: new ImageWMS({
      url: 'https://' + geoserverPublicHost + '/geoserver/wms',
      params: params,
      serverType: 'geoserver',
      crossOrigin: 'anonymous',
    }),
  });
  theLayer.getSource().setImageLoadFunction(function (image, src) {
    image.getImage().src = src + '&token=' + sessionStorage.getItem("token");
  });

  if (minZoom != null) {
    theLayer.setMinZoom(minZoom);
  }
  if (maxZoom != null) {
    theLayer.setMaxZoom(maxZoom);
  }

  theLayer.isUserLayer = isUserLayer;
  theLayer.excludeFromSwitcherLayer = excludeFromSwitcherLayer;

  theLayerGroup.getLayers().push(theLayer);

  return theLayer;
}

function getQueryParam(queryParam) {
  return (window.location.search.match(new RegExp('[?&]' + queryParam + '=([^&]+)')) || [, null])[1];
}

function checkUserLoggedIn() {
  var cvalue = getCookie("auth");
  if (cvalue == 1) {
    // User logged in
    liConnectStrava.style.display = "none";
    //liLogIn.style.display = "none";
    liLogOut.style.display = "block";

    liActivityFilter.style.display = (activitiesLayerInitialVisibility ? "block" : "none");
    liRouteFilter.style.display = (routesLayerInitialVisibility ? "block" : "none");
    liUploadStravaRoute.style.display = (routesLayerInitialVisibility ? "block" : "none");
    liCreateRoute.style.display = (routesLayerInitialVisibility && isAuthorizedCreateRoute ? "block" : "none");
    liReport.style.display = (isAuthorizedShowReport ? "block" : "none");

    var token = sessionStorage.getItem("token");
    if (token == null) {
      var redirectUri = window.location.origin + "/svc/exchange_token"; //+ "/" + window.location.hash;
      console.log("redirect: " + redirectUri);
      //Full access, force
      //window.location.href = "https://www.strava.com/oauth/authorize?client_id=65120&response_type=code&redirect_uri=" + redirectUri + "&approval_prompt=force&scope=read,activity:write,activity:read_all,read_all";
      //Full access, auto
      window.location.href = "https://www.strava.com/oauth/authorize?client_id=65120&response_type=code&redirect_uri=" + redirectUri + "&approval_prompt=auto&scope=read,activity:write,activity:read_all,read_all";
      // Read, force 
      //window.location.href = "https://www.strava.com/oauth/authorize?client_id=65120&response_type=code&redirect_uri=" + redirectUri + "&approval_prompt=force&scope=read,activity:read_all,read_all";
      // Read, auto 
      //window.location.href = "https://www.strava.com/oauth/authorize?client_id=65120&response_type=code&redirect_uri=" + redirectUri + "&approval_prompt=auto&scope=read,activity:read_all,read_all";
      // Minimal
      //window.location.href = "https://www.strava.com/oauth/authorize?client_id=65120&response_type=code&redirect_uri=" + redirectUri + "&approval_prompt=force&scope=read";
    } else {
      // Get Athlete
      getAthleteByTokenAsync(token, function (token, err, athlete) {
        if (err) {
          console.log(err.toString());
        } else {
          console.log(athlete);

          if (athlete == null) {
            console.log("User for token " + token + " not found");
            var redirectUri = window.location.origin + "/svc/exchange_token";
            window.location.href = "https://www.strava.com/oauth/authorize?client_id=65120&response_type=code&redirect_uri=" + redirectUri + "&approval_prompt=auto&scope=read,activity:write,activity:read_all,read_all";

          } else {
            // set athlete
            oModel.athlete = athlete;
            avatar.innerHTML = '<img src="' + oModel.athlete.profilemedium + '">';
            popoverAvatar.setAttribute("header-text", oModel.athlete.firstname + " " + oModel.athlete.lastname);

            calcActivityFilterValuesAndCenter();
            calcBacklog();

            //showLayers();
            updateUserLayers(athlete.id);
            showMap();
          }

        }
      }, token);

    }
  } else {
    // User logged off
    liConnectStrava.style.display = "block";
    //liLogIn.style.display = "block";
    liLogOut.style.display = "none";

    oModel.athlete = null;
    avatar.innerHTML = '';
    popoverAvatar.setAttribute("header-text", "");


    //hideLayers();
    updateUserLayers(null);
    
    resetLayers();
    //Hide map
    if (hideMapIfNotLoggedOn) {
      hideMap();
    } else {
      showMap();
    }

  }
}

function updateLayerStyles(layer, styles) {
  if (layer instanceof ImageLayer) {
    var params = layer.getSource().getParams();
    params.styles = styles;
    layer.getSource().updateParams(params);
  }
}

function updateLayer(layer, athlete_id, id, startDate, endDate, sportType, stravaId) {
  // Only one filter may be changed at a time...

  if (layer instanceof ImageLayer) {
    var params = layer.getSource().getParams();
    var cql_filters = layer.getSource().getParams().cql_filter;
    var new_cql_filters = "";

    layer.getSource().getParams().LAYERS.split(",").forEach(function (layerName, i) {
      var layerFilter = cql_filters.split(";")[i];
      var new_layerFilter = layerFilter;

      // we cannot use getNewFilter as we search on 'and ...'
      if (athlete_id != null) {
        var matches_athlete_id = layerFilter.match(/athlete_id='[0-9]+'/);
        if (matches_athlete_id != null) {
          var layerFilter_athlete_id = matches_athlete_id[0];
          if (layerFilter_athlete_id != null) {
            var old_athlete_id = layerFilter_athlete_id.toString().match(/[0-9]+/)[0];
            var new_layerFilter_athlete_id = layerFilter_athlete_id.replace(old_athlete_id, athlete_id);
            new_layerFilter = layerFilter.replace(layerFilter_athlete_id, new_layerFilter_athlete_id);
          }
        }
      }

      if (id != null) {
        new_layerFilter = getNewFilter(layerFilter, "id", (id == "" ? null : id));
      }

      if (stravaId != null) {
        new_layerFilter = getNewFilter(layerFilter, "strava_id", (stravaId == "" ? null : stravaId));
      }

      if (startDate != null && endDate != null) {
        var matches_dates = layerFilter.match(/start_date_local between '....-..-..T..:..:......Z' and '....-..-..T..:..:......Z'/);
        if (matches_dates != null) {
          var layerFilter_dates = matches_dates[0];
          if (layerFilter_dates != null) {
            var new_layerFilter_dates = "start_date_local between '" + formatDateTimeToUTC(startDate) + "' and '" + formatDateTimeToUTC(endDate) + "'";
            new_layerFilter = layerFilter.replace(layerFilter_dates, new_layerFilter_dates);
          }
        }

      }

      // regex logica nog aanpassen want zal ook nog alle parameters achter sport_type meennemen indien je .* gebruikt
      if (sportType != null) {
        new_layerFilter = getNewFilter(layerFilter, "sport_type", (sportType == "All" ? null : sportType));
      }

      new_cql_filters += (i == 0 ? "" : ";") + new_layerFilter;
    });

    if (params.cql_filter != new_cql_filters) {
      params.cql_filter = new_cql_filters;
      layer.getSource().updateParams(params);
      //console.log("Layer '" + layer.getProperties().title + "' filter updated from (" + cql_filters + ") to (" + new_cql_filters + ")");

    }
  }
}

function getNewFilter(filter, key, newValue) {
  var newFilter = filter;

  var indexStart = filter.indexOf("and " + key + "='");
  if (indexStart != -1) {
    var remainder = filter.substring(indexStart + key.length + 6, filter.length);
    var indexEnd = remainder.indexOf("'");
    if (indexEnd != -1) {
      if (newValue == null) {
        newFilter = filter.substring(0, indexStart) + remainder.substring(indexEnd + 2, remainder.length);
      } else {
        newFilter = filter.substring(0, indexStart + key.length + 6) + newValue + remainder.substring(indexEnd, remainder.length);
      }
    }
  } else {
    if (newValue != null) {
      newFilter = filter + " and " + key + "='" + newValue + "'";
    }
  }

  return newFilter;
}

function updateUserLayers(athlete_id) {
  var layerGroups = map.getLayers();
  layerGroups.forEach(function (layerGroup) {
    layerGroup.getLayers().forEach(function (layer) {
      if (layer.isUserLayer) {
        updateLayer(layer, athlete_id, null, null, null, null, null);
      }
    });
  });

}

function showOrHideLayers(visible) {
  var layerGroups = map.getLayers();
  layerGroups.forEach(function (layerGroup) {
    layerGroup.getLayers().forEach(function (layer) {
      if (layerGroup.getProperties().title == baseLayerGroupTitle && layer.getProperties().title == osmLayerTitle) {

      } else {
        layer.setVisible(visible);

      }

    });
  });

}

function showMap() {
  e_map.style.display = "block";
}

function hideMap() {
  e_map.style.display = "none";
}

function showLayers() {
  showOrHideLayers(true);
}

function hideLayers() {
  showOrHideLayers(false);
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function findOsmId(osm_id, features) {
  var theFeature = null;
  for (let i = 0; i < features.length; i++) {
    if (features[i].properties.osm_id == osm_id) {
      theFeature = features[i];
      break;
    }
  }
  return theFeature;
}

function hideSelectedActivityLayers() {
  updateSelectedActivityLayers("0");
}
function updateSelectedActivityLayers(id) {
  updateLayer(highlightActivityLayer, null, id, null, null, null, null);
  updateLayer(distanceActivityLayer, null, id, null, null, null, null);
  updateLayer(surfaceActivityLayer, null, id, null, null, null, null);

  if (id == "0") {
    clearTimeout(selectedActivityLayersSetInVisibleTimeout);
    selectedActivityLayersSetInVisibleTimeout = setTimeout(selectedActivityLayersSetInVisible, 2000);
  } else {
    clearTimeout(selectedActivityLayersSetInVisibleTimeout);
    selectedActivityLayersSetVisible(true);
  }

}

function selectedActivityLayersSetInVisible() {
  selectedActivityLayersSetVisible(false);
}

function selectedActivityLayersSetVisible(visible) {
  //console.log("selectedActivityLayersSetVisible: " + visible);
  highlightActivityLayer.setVisible(visible);
  distanceActivityLayer.setVisible(visible);
  surfaceActivityLayer.setVisible(visible);
}


function hideSelectedRouteLayers() {
  updateSelectedRouteLayers("0");
}
function updateSelectedRouteLayers(id) {
  updateLayer(highlightRouteLayer, null, id, null, null, null, null);
  updateLayer(distanceRouteLayer, null, id, null, null, null, null);
  updateLayer(surfaceRouteLayer, null, id, null, null, null, null);

  //indien je de layer op invisible zet, is de update van de layer mogelijks nog niet gebeurd... (en wordt ze geskipt)
  //je mag een layer dus pas op invisible zetten als alle tiles gerenderd zijn...Of eventueel invisible zetten met een vetraging?
  //selectedRouteLayersSetVisible(id != "0");
  if (id == "0") {
    clearTimeout(selectedRouteLayersSetInVisibleTimeout);
    selectedRouteLayersSetInVisibleTimeout = setTimeout(selectedRouteLayersSetInVisible, 2000);
  } else {
    clearTimeout(selectedRouteLayersSetInVisibleTimeout);
    selectedRouteLayersSetVisible(true);
  }
}

function selectedRouteLayersSetInVisible() {
  selectedRouteLayersSetVisible(false);
}

function selectedRouteLayersSetVisible(visible) {
  //console.log("selectedRouteLayersSetVisible: " + visible);
  highlightRouteLayer.setVisible(visible);
  distanceRouteLayer.setVisible(visible);
  surfaceRouteLayer.setVisible(visible);
}

function updateSelectedRouteLayersOnlyDistance(id) {
  updateLayer(highlightRouteLayer, null, "0", null, null, null, null);
  updateLayer(distanceRouteLayer, null, id, null, null, null, null);
  updateLayer(surfaceRouteLayer, null, "0", null, null, null, null);
}

function renderAllFeatures(layerFeaturesFetches, pixel) {

  var nFeatures = 0;

  hideSelectedActivityLayers();
  hideSelectedRouteLayers();

  var featureGroups = [];
  // Make sure features will be displayes/sorted according to layer switcher order
  map.getLayers().forEach(function (layerGroup, iLayerGroup) {
    layerGroup.getLayers().forEach(function (layer, iLayer) {
      for (let i = 0; i < layerFeaturesFetches.length; i++) {
        var layerFeaturesFetch = layerFeaturesFetches[i];
        if (layerFeaturesFetch.layer == layer) {
          layerFeaturesFetch.resJson.features.forEach(function (feature, iFeature) {
            if (iFeature == 0) {
              var featureGroup = {
                layer: layer,
                features: []
              }
              featureGroups.push(featureGroup);
            }

            // duplicate osm_id's may exist (way, area ...); we will filter as not relevant here...
            if (feature.properties.osm_id) {
              if (findOsmId(feature.properties.osm_id, featureGroups[featureGroups.length - 1].features) == null) {
                featureGroups[featureGroups.length - 1].features.push(feature);
                nFeatures += 1;
              }
            } else {
              featureGroups[featureGroups.length - 1].features.push(feature);
              nFeatures += 1;
            }

          });

          break;
        }

      }

    });
  });

  switch (nFeatures) {
    case 0:
      // do nothing
      break;
    case 1:
      if (featureGroups[0].layer == activitiesLayer) {
        updateSelectedActivityLayers(featureGroups[0].features[0].properties.id);
      } else {
        hideSelectedActivityLayers();
      }
      if (featureGroups[0].layer == routesLayer) {
        updateSelectedRouteLayers(featureGroups[0].features[0].properties.id);
      } else {
        hideSelectedRouteLayers()
      }

      showFeature(featureGroups[0].features[0], pixel);
      break;
    default:
      hideSelectedActivityLayers();
      hideSelectedRouteLayers()

      // show features
      showMultipleFeatures(featureGroups, pixel);
  }
}



function showMultipleFeatures(featureGroups, pixel) {

  popoverFeature.setAttribute("header-text", "Multiple Selection");
  popoverFeature.innerHTML = "";

  var popoverFeatureContent = document.createElement("div");
  popoverFeatureContent.setAttribute("class", "popover-content");
  popoverFeature.appendChild(popoverFeatureContent);

  featureGroups.forEach(function (featureGroup) {

    // sort features within group
    if (featureGroup.layer == activitiesLayer) {
      featureGroup.features.sort(function (a, b) {
        // sort newest first
        return b.properties.start_date_local > a.properties.start_date_local;
      });
    } else {
      if (featureGroup.layer == routesLayer) {
        featureGroup.features.sort(function (a, b) {
          // sort newest first
          return b.properties.created_at > a.properties.created_at;
        });
      } else {
        featureGroup.features.sort(function (a, b) {
          // sort alphabetic
          let x = (a.properties.type + ": " + a.properties.name).toLowerCase();
          let y = (b.properties.type + ": " + b.properties.name).toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        });
      }
    }

    // Render features
    featureGroup.features.forEach(function (feature) {
      renderFeature(popoverFeatureContent, feature, true);
    });

  });

  // Show popup
  popoverAnchor.style.position = "absolute";
  popoverAnchor.style.left = (pixel[0] + e_map.getBoundingClientRect().left) + "px";
  popoverAnchor.style.top = (pixel[1] + e_map.getBoundingClientRect().top) + "px";
  popoverFeature.showAt(popoverAnchor, true);

}


function renderFeature(popoverContentElement, feature, inMultipleSelect) {
  var f = feature.properties;

  if (f.type == null) {
    f.type = feature.id.split(".")[0];
    if (f.name == null) {
      f.name = feature.id.split(".")[1];
    }
  }

  if (inMultipleSelect) {
    var ui5Panel = document.createElement("ui5-panel");
    var headerText = "";

    switch (feature.id.split(".")[0]) {
      case activitiesLayerName:
        headerText = formatStravaActivitySportType(f.sport_type) + " Activity" + (f.name == "" || f.name == null ? "" : ": " + f.name);
        ui5Panel.setAttribute("activity_id", f.id);
        break;
      case routesLayerName:
        headerText = formatStravaRouteSubType(f.type, f.sub_type) + " Route" + (f.name == "" || f.name == null ? "" : ": " + f.name);
        ui5Panel.setAttribute("route_id", f.id);
        break;
      default:
        headerText = capitalize(f.type.replaceAll("_", " ")) + (f.name == "" || f.name == null ? "" : ": " + f.name);
        ui5Panel.setAttribute(feature.id.split(".")[0] + "_id", f.id);
    }

    ui5Panel.setAttribute("header-text", headerText);
    ui5Panel.setAttribute("collapsed", "true");

    ui5Panel.addEventListener(
      "toggle",
      function (event) {

        if (this.getAttribute("activity_id") != null) {
          if (this.collapsed) {
            hideSelectedActivityLayers();
          } else {
            updateSelectedActivityLayers(this.getAttribute("activity_id"));
          }
        }

        if (this.getAttribute("route_id") != null) {
          if (this.collapsed) {
            hideSelectedRouteLayers()
          } else {
            updateSelectedRouteLayers(this.getAttribute("route_id"));
          }
        }

      }
    );

    var ui5PanelContentElement = document.createElement("div");
    ui5Panel.appendChild(ui5PanelContentElement);

    var ui5PanelFooterElement = document.createElement("div");
    ui5PanelFooterElement.setAttribute("class", "panel-footer");
    ui5Panel.appendChild(ui5PanelFooterElement);

    popoverContentElement.appendChild(ui5Panel);
  } else {
    switch (feature.id.split(".")[0]) {
      case activitiesLayerName:
      case routesLayerName:
        var popoverFooterElement = document.createElement("div");
        popoverFooterElement.setAttribute("slot", "footer");
        popoverFooterElement.setAttribute("class", "popover-footer");
        popoverFeature.appendChild(popoverFooterElement);

        break;
    }

  }

  switch (feature.id.split(".")[0]) {
    case activitiesLayerName:
      renderFeatureActivity(inMultipleSelect ? ui5PanelContentElement : popoverContentElement, inMultipleSelect ? ui5PanelFooterElement : popoverFooterElement, feature);
      break;
    case routesLayerName:
      renderFeatureRoute(inMultipleSelect ? ui5PanelContentElement : popoverContentElement, inMultipleSelect ? ui5PanelFooterElement : popoverFooterElement, feature);
      break;
    default:
      renderFeatureOther(inMultipleSelect ? ui5PanelContentElement : popoverContentElement, feature);
  }
}

function formatStravaActivitySportType(sport_type) {
  var formatted;
  switch (sport_type) {
    case "VirtualRun":
      formatted = "Virtual Run";
      break;
    case "VirtualRide":
      formatted = "Virtual Ride";
      break;
    case "GravelRide":
      formatted = "Gravel";
      break;
    case "MountainBikeRide":
      formatted = "MTB";
      break;
    default:
      formatted = capitalize(sport_type.replaceAll("_", " "));
  }
  return formatted;

}

function formatStravaRouteSubType(type, sub_type) {
  var formatted;

  if (type == null || sub_type == null) {
    formatted = "Custom";
  } else {
    if (type == 1) {
      formatted = "Ride";
    } else if (type == 2) {
      formatted = "Run";
    } else if (type == 3) {
      formatted = "Walk";
    } else if (type == 4) {
      formatted = "Hike";
    } else if (type == 5) {
      formatted = "Trail Run";
    } else if (type == 7) {
      formatted = "Mountain Bike";
    } else if (type == 6) {
      formatted = "Gravel Ride";
    } else {
      formatted = type.toString() + " " + sub_type.toString();
    }

    /*
    if (type == 1 && sub_type == 5) {
      formatted = "Ride";
    } else if (type == 2 && sub_type == 5) {
      formatted = "Run";
    } else if (type == 3 && sub_type == 5) {
      formatted = "Walk";
    } else if (type == 4 && sub_type == 5) {
      formatted = "Hike";
    } else if (type == 5 && sub_type == 5) {
      formatted = "Trail Run";
    } else if (type == 7 && sub_type == 5) {
      formatted = "Mountain Bike";
    } else if (type == 6 && sub_type == 5) {
      formatted = "Gravel Ride";
    } else {
      formatted = type.toString() + " " + sub_type.toString();
    }
    */
  }



  /*
  switch (sub_type) {
    case 1:
      formatted = (type == 1 ? "Ride/MTB" : "Walk");
      break;
    case 2:
      formatted = "Cycling";
      break;
    case 3:
      formatted = "Gravel";
      break;
    case 4:
      formatted = "Hike";
      break;
    case 5:
      formatted = "Walk";
      break;
    default:
      formatted = sub_type;
  }
  */

  return formatted;

}

function formatStravaActivityDateTimeToddmmyyhhmm(utcDate) {
  //  
  var d = new Date(Date.parse(utcDate));
  return formatDateTimeToddmmyyhhmm(d);
}



function formatStravaActivityDateTime(utcDate) {
  //  
  var weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var d = new Date(Date.parse(utcDate));
  return String(d.getFullYear()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + " " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + "u, " + weekdays[d.getDay()];

}

function formatStravaActivityDistance(distanceInMeter) {
  // m to km, and 2 decimals
  return (Math.round(distanceInMeter / 10) / 100).toFixed(2) + " km"; //maybe unit not correct
}

function formatStravaActivityElevation(theElevationInMeter) {
  return String(Math.round(theElevationInMeter)) + " m"; //maybe unit not correct
}

function formatStravaActivityTimeElapsed(theTimeElapsedInSeconds) {
  var theTimeLeftHours = theTimeElapsedInSeconds;
  var nHours = Math.trunc(theTimeLeftHours / 3600);
  var theTimeLeftMinutes = theTimeLeftHours - (nHours * 3600);
  var nMinutes = Math.trunc(theTimeLeftMinutes / 60);
  var theTimeLeftSeconds = theTimeLeftMinutes - (nMinutes * 60);
  //return nHours + ":" + String(nMinutes).padStart(2, "0") + ":" + String(theTimeLeftSeconds).padStart(2, "0");
  return nHours + "h " + String(nMinutes).padStart(2, "0") + "m " + String(theTimeLeftSeconds).padStart(2, "0") + "s";
}

function renderFeatureActivity(parentElement, footerElement, feature) {

  var f = feature.properties;

  var ui5TableRow = document.createElement("ui5-table-row");

  var ui5TableCellKey = document.createElement("ui5-table-cell");
  if (f.type != null && f.type != "") {
    var ui5Badge = document.createElement("ui5-badge");
    ui5Badge.setAttribute("colorScheme", "1");
    ui5Badge.appendChild(document.createTextNode(formatStravaActivitySportType(f.sport_type)));
    ui5TableCellKey.appendChild(ui5Badge);
  }
  ui5TableRow.appendChild(ui5TableCellKey);

  var ui5TableCellValue = document.createElement("ui5-table-cell");


  // Surface picture
  var imgHeight = 100;
  var imgSrc = '/dashboard/activities/' + f.id + '/map?type=surface&height=' + imgHeight + '&token=' + sessionStorage.getItem("token");
  //var imgHref = '/dashboard/activities/' + f.id + '/map?type=surface_with_distance&height=800&token=' + sessionStorage.getItem("token");

  var a = document.createElement("a");
  //a.setAttribute("href", imgHref);
  //a.setAttribute("target", "_blank");
  a.setAttribute("href", "#");
  a.setAttribute("onclick", "javascript:filterActivitiesOnStravaId('" + f.strava_id + "');");

  var div = document.createElement("div");
  div.setAttribute("class", "polaroid");
  a.appendChild(div);
  var img = document.createElement("img");
  img.setAttribute("src", imgSrc);
  img.setAttribute("height", imgHeight);
  img.setAttribute("title", "Set Filter");
  div.appendChild(img);
  ui5TableCellValue.appendChild(a);

  // Photo
  if (f.photo_100 != null && f.photo_100 != "") {
    var imgSrc = f.photo_100;
    var imgHref = f.photo_100;
    var imgHeight = 100;

    var a = document.createElement("a");
    a.setAttribute("href", imgHref);
    a.setAttribute("target", "_blank");
    var div = document.createElement("div");
    div.setAttribute("class", "polaroid_right");
    a.appendChild(div);
    var img = document.createElement("img");
    img.setAttribute("src", imgSrc);
    img.setAttribute("height", imgHeight);
    div.appendChild(img);
    ui5TableCellValue.appendChild(a);
  }



  ui5TableRow.appendChild(ui5TableCellValue);
  parentElement.appendChild(ui5TableRow);

  var keys = ["start_date", "description", "private_note", "segment_cities", "distance", "moving_time", "total_elevation_gain", "strava_id", "surface_total"];
  var keyTranslations = ["Date", "Description", "Private Notes", "Segment Cities", "Distance", "Moving Time", "Elevation", "Strava Id", "Surface"];
  keys.forEach(function (key, iKey) {
    if (f[key] != "" && f[key] != null) {
      var ui5TableRow = document.createElement("ui5-table-row");
      var ui5TableCellKey = document.createElement("ui5-table-cell");
      var ui5TableCellValue = document.createElement("ui5-table-cell");
      switch (key) {
        /*
        case "strava_id":
          ui5TableCellKey.appendChild(document.createTextNode(keyTranslations[iKey] + ":"));
          ui5TableRow.appendChild(ui5TableCellKey);
          var ui5Link = document.createElement("ui5-link");
          ui5Link.setAttribute("href", "https://www.strava.com/activities/" + f[key]);
          ui5Link.setAttribute("target", "_blank");
          ui5Link.appendChild(document.createTextNode(f[key]));
          ui5TableCellValue.appendChild(ui5Link);
          ui5TableRow.appendChild(ui5TableCellValue);

          break;
        */
        default:
          ui5TableCellKey.appendChild(document.createTextNode(keyTranslations[iKey] + ":"));
          ui5TableRow.appendChild(ui5TableCellKey);

          var theValue;
          switch (key) {
            case "start_date":
              theValue = formatStravaActivityDateTimeToddmmyyhhmm(f[key]);
              break;
            case "distance":
              theValue = formatStravaActivityDistance(f[key]); // maybe unit not correct
              break;
            case "moving_time":
              theValue = formatStravaActivityTimeElapsed(f[key]); // maybe unit not correct
              break;
            case "total_elevation_gain":
              theValue = formatStravaActivityElevation(f[key]); // maybe unit not correct
              break;
            default:
              theValue = f[key];
          }

          ui5TableCellValue.appendChild(document.createTextNode(theValue));
          ui5TableRow.appendChild(ui5TableCellValue);

      }

      parentElement.appendChild(ui5TableRow);

    }


  });

  var ui5Link = document.createElement("ui5-link");
  ui5Link.setAttribute("href", "https://www.strava.com/activities/" + f["strava_id"]);
  ui5Link.setAttribute("target", "_blank");
  ui5Link.setAttribute("class", "center strava margintop");
  ui5Link.appendChild(document.createTextNode("View on Strava"));
  parentElement.appendChild(ui5Link);

  var ui5ButtonDownload = document.createElement("ui5-button");
  ui5ButtonDownload.setAttribute("icon", "sap-icon://download-from-cloud");
  ui5ButtonDownload.appendChild(document.createTextNode("Export GPX"));
  ui5ButtonDownload.setAttribute("strava_id", f["strava_id"]);
  footerElement.appendChild(ui5ButtonDownload);
  ui5ButtonDownload.addEventListener(
    "click",
    function (event) {
      window.open("https://www.strava.com/activities/" + this.getAttribute("strava_id") + "/export_gpx")
    }
  );

  var ui5ButtonDownloadKml = document.createElement("ui5-button");
  ui5ButtonDownloadKml.setAttribute("icon", "sap-icon://download-from-cloud");
  ui5ButtonDownloadKml.appendChild(document.createTextNode("Export KML"));
  ui5ButtonDownloadKml.setAttribute("strava_id", f["strava_id"]);
  footerElement.appendChild(ui5ButtonDownloadKml);
  ui5ButtonDownloadKml.addEventListener(
    "click",
    function (event) {
      window.open("/dashboard/activities/" + this.getAttribute("strava_id") + "/export_kml?token=" + sessionStorage.getItem("token"));
    }
  );

  var ui5ButtonMap = document.createElement("ui5-button");
  ui5ButtonMap.setAttribute("icon", "sap-icon://download-from-cloud");
  ui5ButtonMap.appendChild(document.createTextNode("Export Map"));
  ui5ButtonMap.setAttribute("id", f["id"]);
  footerElement.appendChild(ui5ButtonMap);
  ui5ButtonMap.addEventListener(
    "click",
    function (event) {
      window.open("/dashboard/activities/" + this.getAttribute("id") + "/map?type=surface_with_distance&height=400&disposition=attachment&token=" + sessionStorage.getItem("token"))
    }
  );

  var ui5ButtonDelete = document.createElement("ui5-button");
  ui5ButtonDelete.setAttribute("icon", "sap-icon://delete");
  ui5ButtonDelete.setAttribute("activity_id", f["id"]);
  ui5ButtonDelete.setAttribute("activity_name", f["name"]);
  footerElement.appendChild(ui5ButtonDelete);
  ui5ButtonDelete.addEventListener(
    "click",
    function (event) {
      deleteActivity(this.getAttribute("activity_id"), this.getAttribute("activity_name"));
    }
  );

}

function renderFeatureRoute(contentElement, footerElement, feature) {

  var f = feature.properties;

  var ui5TableRow = document.createElement("ui5-table-row");

  var ui5TableCellKey = document.createElement("ui5-table-cell");
  if (f.type != null && f.type != "") {
    var ui5Badge = document.createElement("ui5-badge");
    ui5Badge.setAttribute("colorScheme", "1");
    ui5Badge.appendChild(document.createTextNode(formatStravaRouteSubType(f.type, f.sub_type)));
    ui5TableCellKey.appendChild(ui5Badge);
  }
  ui5TableRow.appendChild(ui5TableCellKey);

  var ui5TableCellValue = document.createElement("ui5-table-cell");

  // Surface picture
  var imgHeight = 100;
  var imgSrc = '/dashboard/routes/' + f.id + '/map?type=surface&height=' + imgHeight + '&token=' + sessionStorage.getItem("token");
  // we use a dummy t parameter to make sure image is not read from cache after (any) route being edited; could be optimized to use route specific timestamps
  imgSrc += '&t=' + editAnyRouteTimestamp;

  var a = document.createElement("a");
  //a.setAttribute("href", imgHref);
  //a.setAttribute("target", "_blank");
  a.setAttribute("href", "#");
  a.setAttribute("onclick", "javascript:filterRoutesOnId('" + f.id + "');");

  var div = document.createElement("div");
  div.setAttribute("class", "polaroid");
  a.appendChild(div);
  var img = document.createElement("img");
  img.setAttribute("src", imgSrc);
  img.setAttribute("height", imgHeight);
  img.setAttribute("title", "Set Filter");
  div.appendChild(img);
  ui5TableCellValue.appendChild(a);

  ui5TableRow.appendChild(ui5TableCellValue);
  contentElement.appendChild(ui5TableRow);

  var keys = ["create_timestamp", "description", "distance", "estimated_moving_time", "elevation_gain", "id", "strava_id_str", "surface_total"];
  var keyTranslations = ["Date", "Description", "Distance", "Moving Time", "Elevation", "Id", "Strava Id", "Surface"];
  keys.forEach(function (key, iKey) {
    if (f[key] != "" && f[key] != null) {
      var ui5TableRow = document.createElement("ui5-table-row");
      var ui5TableCellKey = document.createElement("ui5-table-cell");
      var ui5TableCellValue = document.createElement("ui5-table-cell");
      switch (key) {
        /*
        case "strava_id":
          ui5TableCellKey.appendChild(document.createTextNode(keyTranslations[iKey] + ":"));
          ui5TableRow.appendChild(ui5TableCellKey);
          var ui5Link = document.createElement("ui5-link");
          ui5Link.setAttribute("href", "https://www.strava.com/activities/" + f[key]);
          ui5Link.setAttribute("target", "_blank");
          ui5Link.appendChild(document.createTextNode(f[key]));
          ui5TableCellValue.appendChild(ui5Link);
          ui5TableRow.appendChild(ui5TableCellValue);

          break;
        */
        default:
          ui5TableCellKey.appendChild(document.createTextNode(keyTranslations[iKey] + ":"));
          ui5TableRow.appendChild(ui5TableCellKey);

          var theValue;
          switch (key) {
            case "create_timestamp":
              theValue = formatStravaActivityDateTimeToddmmyyhhmm(f[key]);
              break;
            case "distance":
              theValue = formatStravaActivityDistance(f[key]); // maybe unit not correct
              break;
            case "estimated_moving_time":
              theValue = formatStravaActivityTimeElapsed(f[key]); // maybe unit not correct
              break;
            case "elevation_gain":
              theValue = formatStravaActivityElevation(f[key]); // maybe unit not correct
              break;
            default:
              theValue = f[key];
          }

          ui5TableCellValue.appendChild(document.createTextNode(theValue));
          ui5TableRow.appendChild(ui5TableCellValue);

      }

      contentElement.appendChild(ui5TableRow);

    }


  });

  if (f["strava_id_str"] != null) {
    var ui5Link = document.createElement("ui5-link");
    ui5Link.setAttribute("href", "https://www.strava.com/routes/" + f["strava_id_str"]);
    ui5Link.setAttribute("target", "_blank");
    ui5Link.setAttribute("class", "center strava margintop");
    ui5Link.appendChild(document.createTextNode("View on Strava"));
    contentElement.appendChild(ui5Link);

    var ui5ButtonRefreshFromStrava = document.createElement("ui5-button");
    ui5ButtonRefreshFromStrava.setAttribute("icon", "sap-icon://upload-to-cloud");
    ui5ButtonRefreshFromStrava.appendChild(document.createTextNode("Refresh from Strava"));
    ui5ButtonRefreshFromStrava.setAttribute("strava_id_str", f["strava_id_str"]);
    footerElement.appendChild(ui5ButtonRefreshFromStrava);
    ui5ButtonRefreshFromStrava.addEventListener(
      "click",
      function (event) {
        editAnyRouteTimestamp = new Date().getTime();
        popoverFeature.close();
        uploadRoute(this.getAttribute("strava_id_str"));
      }
    );
  
  }

  var ui5ButtonDownload = document.createElement("ui5-button");
  ui5ButtonDownload.setAttribute("icon", "sap-icon://download-from-cloud");
  ui5ButtonDownload.appendChild(document.createTextNode("Export GPX"));
  ui5ButtonDownload.setAttribute("route_id", f["id"]);
  if (f["strava_id_str"] != null) {
    ui5ButtonDownload.setAttribute("strava_id_str", f["strava_id_str"]);
  }
  footerElement.appendChild(ui5ButtonDownload);
  ui5ButtonDownload.addEventListener(
    "click",
    function (event) {
      if (this.getAttribute("strava_id_str") != null) {
        window.open("https://www.strava.com/routes/" + this.getAttribute("strava_id_str") + "/export_gpx")
      } else {
        window.open("/dashboard/routes/" + this.getAttribute("route_id") + "/export_gpx?token=" + sessionStorage.getItem("token"));
      }
    }
  );


  var ui5ButtonMap = document.createElement("ui5-button");
  ui5ButtonMap.setAttribute("icon", "sap-icon://download-from-cloud");
  ui5ButtonMap.appendChild(document.createTextNode("Export Map"));
  ui5ButtonMap.setAttribute("id", f["id"]);
  footerElement.appendChild(ui5ButtonMap);
  ui5ButtonMap.addEventListener(
    "click",
    function (event) {
      window.open("/dashboard/routes/" + this.getAttribute("id") + "/map?type=surface_with_distance&height=400&disposition=attachment&token=" + sessionStorage.getItem("token"))
    }
  );

  var ui5ButtonCopy = document.createElement("ui5-button");
  ui5ButtonCopy.setAttribute("icon", "sap-icon://copy");
  ui5ButtonCopy.setAttribute("route_id", f["id"]);
  ui5ButtonCopy.setAttribute("tooltip", "Copy and Edit");
  footerElement.appendChild(ui5ButtonCopy);
  ui5ButtonCopy.addEventListener(
    "click",
    function (event) {
      copyRoute(this.getAttribute("route_id"));
    }
  );

  if (f["strava_id_str"] == null) {
    var ui5ButtonEdit = document.createElement("ui5-button");
    ui5ButtonEdit.setAttribute("icon", "sap-icon://edit");
    ui5ButtonEdit.setAttribute("route_id", f["id"]);
    ui5ButtonEdit.setAttribute("route_name", f["name"]);
    ui5ButtonEdit.setAttribute("tooltip", "Edit");
    footerElement.appendChild(ui5ButtonEdit);
    ui5ButtonEdit.addEventListener(
      "click",
      function (event) {
        editRoute(this.getAttribute("route_id"), this.getAttribute("route_name"));
      }
    );
  }

  var ui5ButtonDelete = document.createElement("ui5-button");
  ui5ButtonDelete.setAttribute("icon", "sap-icon://delete");
  ui5ButtonDelete.setAttribute("route_id", f["id"]);
  ui5ButtonDelete.setAttribute("route_name", f["name"]);
  ui5ButtonDelete.setAttribute("tooltip", "Delete");
  footerElement.appendChild(ui5ButtonDelete);
  ui5ButtonDelete.addEventListener(
    "click",
    function (event) {
      deleteRoute(this.getAttribute("route_id"), this.getAttribute("route_name"));
    }
  );



}

function filterActivitiesOnStravaId(stravaId) {
  console.log(stravaId);
  inputActivityStravaId.setAttribute("value", stravaId);
  updateLayer(activitiesLayer, null, null, null, null, null, stravaId);
  //if (popoverFeature.isOpen()) {
  if (popoverFeature.open) {
    popoverFeature.close();
  }
}

function filterRoutesOnStravaId(stravaId) {
  inputFilterRouteStravaId.setAttribute("value", stravaId);
  updateLayer(routesLayer, null, null, null, null, null, stravaId);
  //if (popoverFeature.isOpen()) {
  if (popoverFeature.open) {
    popoverFeature.close();
  }
}

function filterRoutesOnId(id) {
  inputFilterRouteId.setAttribute("value", id);
  updateLayer(routesLayer, null, id, null, null, null, null);
  //if (popoverFeature.isOpen()) {
  if (popoverFeature.open) {
    popoverFeature.close();
  }
}

function renderFeatureOther(parentElement, feature) {

  var f = feature.properties;

  var ui5TableRow = document.createElement("ui5-table-row");
  var ui5TableCellKey = document.createElement("ui5-table-cell");
  if (f.type != null && f.type != "") {
    var ui5Badge = document.createElement("ui5-badge");
    ui5Badge.setAttribute("colorScheme", "1");
    ui5Badge.appendChild(document.createTextNode(f.type.replaceAll("_", " ")));
    ui5TableCellKey.appendChild(ui5Badge);
  }
  ui5TableRow.appendChild(ui5TableCellKey);
  var ui5TableCellValue = document.createElement("ui5-table-cell");

  if (f.image != null && f.image != "") {
    var heritage_operator = null;
    for (var key in f) {
      switch (key) {
        case 'heritage_operator':
          heritage_operator = f[key];
          break;
      }
    }

    var imgSrc = f.image;
    var imgHref = null;
    var imgHeight = 100;

    if (imgSrc.substring(0, 7) == "http://" || imgSrc.substring(0, 8) == "https://") {
      if (imgSrc.substring(0, 26) == "https://photos.app.goo.gl/") {
        imgHref = imgSrc;
        imgSrc = "https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Google_Photos_icon_%282020%29.svg/59px-Google_Photos_icon_%282020%29.svg.png";
        imgHeight = 29;
        //html += '<a href="' + imgHref + '" target="_blank">' + '<div class="polaroid"><img src="' + imgSrc + '" height="29"></div>' + '</a>';
      } else {
        imgHref = f.image;
        imgSrc = imgSrc.replace("://commons.wikimedia.org/wiki/File:", "://commons.wikimedia.org/wiki/Special:FilePath/");
      }
    } else {
      if (heritage_operator == "OnroerendErfgoed") {
        if (imgSrc.substring(0, 5) == "File:") {
          var splitArray = imgSrc.split(" - ");
          switch (splitArray.length) {
            case 1:
              imgHref = "https://commons.wikimedia.org/wiki/" + imgSrc;
              imgSrc = imgHref.replace("://commons.wikimedia.org/wiki/File:", "://commons.wikimedia.org/wiki/Special:FilePath/");
              break;
            case 3:
              var imageId = splitArray[1];
              imgHref = "https://beeldbank.onroerenderfgoed.be/images/" + imageId;
              imgSrc = imgHref + "/content/medium";
              break;
            default:

          }
        } else {

        }
      } else {
        if (imgSrc.substring(0, 5) == "File:") {
          imgHref = "https://commons.wikimedia.org/wiki/" + imgSrc;
          imgSrc = imgHref.replace("://commons.wikimedia.org/wiki/File:", "://commons.wikimedia.org/wiki/Special:FilePath/");
        } else {

        }
      }
    }

    if (imgHref == null) {

    } else {
      //html += '<a href="' + imgHref + '" target="_blank">' + '<div class="polaroid"><img src="' + imgSrc + '" height="29"></div>' + '</a>';

      var a = document.createElement("a");
      a.setAttribute("href", imgHref);
      a.setAttribute("target", "_blank");
      var div = document.createElement("div");
      div.setAttribute("class", "polaroid");
      a.appendChild(div);
      var img = document.createElement("img");
      img.setAttribute("src", imgSrc);
      img.setAttribute("height", imgHeight);
      div.appendChild(img);
      ui5TableCellValue.appendChild(a);

    }

  }

  ui5TableRow.appendChild(ui5TableCellValue);

  parentElement.appendChild(ui5TableRow);

  for (var key in f) {

    if (f[key] != "" && f[key] != null) {
      switch (key) {
        case "name":
        case "osm_id":
        case "geom_type":
        case "general_access_permission":
        case "image":
        case "type":
          break;
        case "website":
        case "heritage_website":
          var ui5TableRow = document.createElement("ui5-table-row");
          var ui5TableCellKey = document.createElement("ui5-table-cell");
          ui5TableCellKey.appendChild(document.createTextNode(capitalize(key).replaceAll("_", " ") + ":"));
          ui5TableRow.appendChild(ui5TableCellKey);
          var ui5TableCellValue = document.createElement("ui5-table-cell");
          var ui5Link = document.createElement("ui5-link");
          ui5Link.setAttribute("href", f[key]);
          ui5Link.setAttribute("target", "_blank");
          ui5Link.appendChild(document.createTextNode(f[key]));
          ui5TableCellValue.appendChild(ui5Link);
          ui5TableRow.appendChild(ui5TableCellValue);
          parentElement.appendChild(ui5TableRow);

          break;
        default:

          var ui5TableRow = document.createElement("ui5-table-row");
          var ui5TableCellKey = document.createElement("ui5-table-cell");
          ui5TableCellKey.appendChild(document.createTextNode(capitalize(key).replaceAll("_", " ") + ":"));
          ui5TableRow.appendChild(ui5TableCellKey);
          var ui5TableCellValue = document.createElement("ui5-table-cell");
          ui5TableCellValue.appendChild(document.createTextNode((key == 'type' ? capitalize(f[key]) : f[key])));
          ui5TableRow.appendChild(ui5TableCellValue);
          parentElement.appendChild(ui5TableRow);

      }

    }


  }


}

function showFeature(feature, pixel) {

  var f = feature.properties;

  if (f.name != null && f.name != "") {
    popoverFeature.setAttribute("header-text", f.name);
  } else {
    popoverFeature.setAttribute("header-text", "");
  }

  // Content
  popoverFeature.innerHTML = "";

  var popoverFeatureContent = document.createElement("div");
  popoverFeatureContent.setAttribute("class", "popover-content");
  popoverFeature.appendChild(popoverFeatureContent);

  renderFeature(popoverFeatureContent, feature, false);

  popoverAnchor.style.position = "absolute";
  popoverAnchor.style.left = (pixel[0] + e_map.getBoundingClientRect().left) + "px";
  popoverAnchor.style.top = (pixel[1] + e_map.getBoundingClientRect().top) + "px";

  var getWebsiteTitles = false;
  if (getWebsiteTitles) {
    waitingToBeFetched = countWebsiteTitles(popoverFeature);
    getWebsiteTitles(popoverFeature);
  } else {
    popoverFeature.showAt(popoverAnchor, true);
  }



}

function isLayerWithinMinMaxZoom(layer) {
  // layer.getVisible() equals true even if not within zoom limits (not displayed), so extra check needed...

  //console.log(layer.getProperties().title + ": " + layer.getMinZoom() + " < " + map.getView().getZoom() + " < " + layer.getMaxZoom());

  var isZoomVisible = true;

  if (layer.getMinZoom() != "-Infinity") {
    if (map.getView().getZoom() > layer.getMinZoom()) {
      // visible
    } else {
      isZoomVisible = false;
    }
  }

  if (layer.getMaxZoom() != "Infinity") {
    if (map.getView().getZoom() < layer.getMaxZoom()) {
    } else {
      isZoomVisible = false;
    }
  }
  //console.log("Zoom visible? " + isZoomVisible)
  return isZoomVisible;
}

function showFeaturesOnCoordinateOrExtent(coordinate, extent, projectionCode, pixel) {

  const viewResolution = /** @type {number} */ (view.getResolution());

  //Uncomment if you want to show busy indicator
  /*
    busyIndicator.style.left = (pixel[0] + e_map.getBoundingClientRect().left) + "px";
    busyIndicator.style.top = (pixel[1] + e_map.getBoundingClientRect().top - 30) + "px";
    busyIndicator.style.display = "block";
  */

  var accessToken = sessionStorage.getItem("token");

  g_layerFeaturesFetches = [];
  var layerGroups = map.getLayers();
  layerGroups.forEach(function (layerGroup, i) {
    layerGroup.getLayers().forEach(function (layer) {
      if (layer instanceof ImageLayer) {
        if (layer.getVisible() && isLayerWithinMinMaxZoom(layer) && layer != highlightActivityLayer && layer != distanceActivityLayer && layer != surfaceActivityLayer && layer != highlightRouteLayer && layer != distanceRouteLayer && layer != surfaceRouteLayer && layer != previewLayer) {

          var urlJson;
          if (coordinate != null) {

            // FEATURE_COUNT default 1 if not set !!
            urlJson = layer.getSource().getFeatureInfoUrl(
              coordinate,
              viewResolution,
              projectionCode, //'EPSG:3857',
              { 'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': '100' }
            );

          }

          urlJson += '&token=' + accessToken;

          if (urlJson) {
            var layerFeaturesFetch = {
              layer: layer,
              url: urlJson,
              resJson: null
            };
            g_layerFeaturesFetches.push(layerFeaturesFetch);

          }

        }
      }
    });
  });


  var resJsons = [];
  g_layerFeaturesFetches.forEach(function (layerFeaturesFetch) {
    getFeatures(layerFeaturesFetch, pixel);

  });


}

async function getFeatures(layerFeaturesFetch, pixel) {
  const response = await fetch(layerFeaturesFetch.url);
  layerFeaturesFetch.resJson = await response.json();
  //console.log(layerFeaturesFetch.layer.getProperties().title + ": " + layerFeaturesFetch.resJson.numberReturned);
  console.log(layerFeaturesFetch.resJson);
  var allFetchesFinished = true;
  for (var i = 0; i < g_layerFeaturesFetches.length; i++) {
    if (g_layerFeaturesFetches[i].resJson == null) {
      allFetchesFinished = false;
      break;
    }
  }
  if (allFetchesFinished) {
    renderAllFeatures(g_layerFeaturesFetches, pixel);
  }
}

function capitalize(theString) {
  return theString[0].toUpperCase() + theString.slice(1);

}


function countWebsiteTitles(element) {

  var counter = 0;
  for (var i = 0; i < element.children.length; i++) {
    var href = element.children[i].getAttribute("href");
    if (href != null) {
      var innerText = element.children[i].innerText;
      if (innerText == href) {
        counter += 1;
      }
    }

    counter += countWebsiteTitles(element.children[i]);
  }
  return counter;
}


var waitingToBeFetched;
function getWebsiteTitles(element) {

  for (var i = 0; i < element.children.length; i++) {
    var href = element.children[i].getAttribute("href");
    if (href != null) {
      var innerText = element.children[i].innerText;
      if (innerText == href) {
        updateElementWithWebsiteTitle(element.children[i], href);
      }
    }
    getWebsiteTitles(element.children[i]);
  }

  if (waitingToBeFetched == 0) {
    //busyIndicator.style.display = "none";
    popoverFeature.showAt(popoverAnchor, true);
  }

}

function updateElementWithWebsiteTitle(element, href) {

  fetch(href)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then((response) => response.text())
    .then((html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      const title = doc.querySelectorAll('title')[0];

      element.innerText = title.innerText;

      waitingToBeFetched -= 1;
      if (waitingToBeFetched == 0) {
        //busyIndicator.style.display = "none";
        popoverFeature.showAt(popoverAnchor, true);
      }
    })
    .catch(error => {
      //console.log(error); // no-cors
      waitingToBeFetched -= 1;
      if (waitingToBeFetched == 0) {
        //busyIndicator.style.display = "none";
        popoverFeature.showAt(popoverAnchor, true);
      }

    });
}


function onloadBody() {
  if (navigator.userAgent.match(/Android/i)) {
    popoverLayerSwitcher.setAttribute("placement-type", "Bottom");
    popoverActivityFilter.setAttribute("placement-type", "Bottom");
    popoverRouteFilter.setAttribute("placement-type", "Bottom");
    popoverBacklog.setAttribute("placement-type", "Bottom");
    popoverUploadRoute.setAttribute("placement-type", "Bottom");
  } else {
    popoverLayerSwitcher.setAttribute("placement-type", "Left");
    popoverActivityFilter.setAttribute("placement-type", "Left");
    popoverRouteFilter.setAttribute("placement-type", "Left");
    popoverBacklog.setAttribute("placement-type", "Left");
    popoverUploadRoute.setAttribute("placement-type", "Left");
  }

  //calcActivityFilterValuesAndCenter();
  //calcBacklog();

  fitMapToFullscreen();

}

function formatDateTimeToddmmyyhhmm(d) {
  return String(String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + d.getFullYear()).padStart(2, "0") + " " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + "u";
}


function formatDateTimeToUTC(d) {
  return String(d.getFullYear()).padStart(2, "0") + "-" + String((d.getMonth() + 1)).padStart(2, "0") + "-" + String(d.getDate()).padStart(2, "0") + "T" + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0") + "." + String(d.getMilliseconds()).padStart(3, "0") + "Z";
}

function formatDateTimeToISO(d) {
  return String(d.getFullYear()).padStart(2, "0") + "-" + String((d.getMonth() + 1)).padStart(2, "0") + "-" + String(d.getDate()).padStart(2, "0");
}

function formatDateTimeToNormal(d) {
  return String(String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + d.getFullYear()).padStart(2, "0");
}

function getDateFromNormal(dateString) {
  //31/12/2023
  var splits = dateString.split("/");
  return new Date(splits[2] + "." + splits[1] + "." + splits[0]);
}

function getDateFromUTCString(utcString) {
  return new Date(utcString);
}

function startOfDay(d) {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
}
function endOfDay(d) {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 23, 59, 0);
}

function datePlusDays(d, days) {
  return new Date(((d.getTime() / 1000) + (3600 * 24 * days)) * 1000);
}

function getMyConfigAsync(par, callbackFunction, callbackFunctionPars) {
  getAsync(window.location.origin + "/svc/getMyConfig", callbackFunction, callbackFunctionPars);
}

function getAthleteDetailByTokenAsync(token, callbackFunction, callbackFunctionPars) {
  getAsync(window.location.origin + "/svc/getAthleteDetailByToken?token=" + token, callbackFunction, callbackFunctionPars);
}

function getActivityDetailByTokenAsync(token, strava_id, callbackFunction, callbackFunctionPars) {
  getAsync(window.location.origin + "/svc/getActivityDetailByToken?token=" + token + "&strava_id=" + strava_id, callbackFunction, callbackFunctionPars);
}

function getAthleteByTokenAsync(token, callbackFunction, callbackFunctionPars) {
  getAsync(window.location.origin + "/svc/getAthleteByToken?token=" + token, callbackFunction, callbackFunctionPars);
}

function getAsync(urlJson, callbackFunction, callbackFunctionPars) {
  //console.log("calling: " + urlJson);
  fetch(urlJson)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then((response) => response.text())
    .then((resJsonData) => {
      var resJson = JSON.parse(resJsonData);
      //console.log(resJson);

      callbackFunction(callbackFunctionPars, null, resJson);
    })
    .catch(error => {
      //console.log(error);
      callbackFunction(callbackFunctionPars, error, null);
    });
}

function postAsync(urlJson, theJson, useBusyIndicator, callbackFunction, callbackFunctionPars) {
  //console.log("calling: " + urlJson);
  if (useBusyIndicator) {
    busyIndicatorShow(e_map);
  }

  fetch(urlJson, {
    method: "POST",
    body: JSON.stringify(theJson),
    headers: { "Content-type": "application/json; charset=UTF-8" }
  })
    .then((response) => {
      if (useBusyIndicator) {
        busyIndicatorHide();
      }

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then((response) => response.text())
    .then((resJsonData) => {
      var resJson = JSON.parse(resJsonData);
      //console.log(resJson);

      callbackFunction(callbackFunctionPars, null, resJson);
    })
    .catch(error => {
      if (useBusyIndicator) {
        busyIndicatorHide();
      }
      //console.log(error);
      callbackFunction(callbackFunctionPars, error, null);
    });
}

function refreshAndShowBacklog(showAtElement) {

  getAthleteDetailByTokenAsync(sessionStorage.getItem("token"), function (showAtElement, err, athleteDetail) {
    if (err) {

    } else {
      renderBacklog(athleteDetail.unprocessedActivities);
      if (athleteDetail.unprocessedActivities.length > 0) {
        popoverBacklog.showAt(showAtElement, true);
      } else {
        popoverBacklog.hide();
        toast.show();
      }
    }
  }, showAtElement)

}


function renderBacklog(unprocessedActivities) {
  tableBacklog.innerHTML = "";

  var tableColumn1 = document.createElement("ui5-table-column");
  tableColumn1.setAttribute("slot", "columns-1");
  var span1 = document.createElement("span");
  span1.appendChild(document.createTextNode("Date"));
  tableColumn1.appendChild(span1);
  tableBacklog.appendChild(tableColumn1);

  var tableColumn2 = document.createElement("ui5-table-column");
  tableColumn2.setAttribute("slot", "columns-2");
  var span2 = document.createElement("span");
  span2.appendChild(document.createTextNode("Strava"));
  tableColumn2.appendChild(span2);
  tableBacklog.appendChild(tableColumn2);

  var tableColumn3 = document.createElement("ui5-table-column");
  tableColumn3.setAttribute("slot", "columns-3");
  var span3 = document.createElement("span");
  span3.appendChild(document.createTextNode("Name"));
  tableColumn3.appendChild(span3);
  tableBacklog.appendChild(tableColumn3);

  unprocessedActivities.forEach(function (unprocessedActivity) {
    var tableRow = document.createElement("ui5-table-row");

    var tableCell1 = document.createElement("ui5-table-cell");
    var span1 = document.createElement("span");
    span1.appendChild(document.createTextNode(formatDateTimeToddmmyyhhmm(getDateFromUTCString(unprocessedActivity.min_create_timestamp))));
    tableCell1.appendChild(span1);
    tableRow.appendChild(tableCell1);

    var tableCell2 = document.createElement("ui5-table-cell");
    var span2 = document.createElement("span");
    var ui5Link = document.createElement("ui5-link");
    ui5Link.setAttribute("href", "https://www.strava.com/activities/" + unprocessedActivity.strava_id);
    ui5Link.setAttribute("target", "_blank");
    ui5Link.appendChild(document.createTextNode(unprocessedActivity.strava_id));
    span2.appendChild(ui5Link);
    tableCell2.appendChild(span2);
    tableRow.appendChild(tableCell2);

    var tableCell3 = document.createElement("ui5-table-cell");
    var span3 = document.createElement("span");
    span3.appendChild(document.createTextNode(unprocessedActivity.name == null ? "< to be synchronized... >" : unprocessedActivity.name));
    tableCell3.appendChild(span3);
    tableRow.appendChild(tableCell3);

    tableBacklog.appendChild(tableRow);

  });

}

function calcBacklog() {
  getAthleteDetailByTokenAsync(sessionStorage.getItem("token"), function (dummy, err, athleteDetail) {
    if (err) {
      liBacklog.style.display = "none";
    } else {
      renderBacklog(athleteDetail.unprocessedActivities);
      if (athleteDetail.unprocessedActivities.length == 0) {
        liBacklog.style.display = "none";
      } else {
        liBacklog.style.display = "block";
      }
    }
  }, null)
}

function calcActivityFilterValuesAndCenter() {

  getAthleteDetailByTokenAsync(sessionStorage.getItem("token"), function (dummy, err, athleteDetail) {

    //Dates
    var dNow = new Date();
    if (err) {
      console.log(err);
      g_dMin = startOfDay(dNow);
    } else {
      if (athleteDetail.activityStats.activity_start_date_local_min == null) {
        g_dMin = startOfDay(dNow);
      } else {
        g_dMin = startOfDay(getDateFromUTCString(athleteDetail.activityStats.activity_start_date_local_min));
      }
    }

    g_dMax = endOfDay(dNow);

    activitiesRangeSlider.setAttribute("min", (g_dMin.getTime() / 1000).toString());
    activitiesRangeSlider.setAttribute("max", (g_dMax.getTime() / 1000).toString());
    activitiesRangeSlider.setAttribute("step", (3600 * 24).toString());
    activitiesRangeSlider.setAttribute("show-tickmarks", "true");

    activitiesDateRangePicker.setAttribute("min-date", formatDateTimeToISO(g_dMin));
    activitiesDateRangePicker.setAttribute("max-date", formatDateTimeToISO(g_dMax));
    activitiesDateRangePicker.setAttribute("format-pattern", "dd/MM/yyyy");

    if (calcDates("all").startDate.getTime() == calcDates("today").startDate.getTime()) {
      liActivityFilter.style.display = "none";
    } else {
      if (calcDates("lastYear").startDate < g_dMin) {
        buttonDateFilterLastYear.style.display = "none";
      }
      if (calcDates("lastMonth").startDate < g_dMin) {
        buttonDateFilterLastMonth.style.display = "none";
      }
      if (calcDates("lastWeek").startDate < g_dMin) {
        buttonDateFilterLastWeek.style.display = "none";
      }
    }

    // Sporttypes
    var default_sport_type = "All";
    comboboxSportType.innerHTML = "";
    var cbItem = document.createElement("ui5-cb-item");
    cbItem.setAttribute("text", default_sport_type);
    comboboxSportType.appendChild(cbItem);
    athleteDetail.sportTypes.forEach(function (sportType) {
      var cbItem = document.createElement("ui5-cb-item");
      cbItem.setAttribute("text", sportType.sport_type);
      comboboxSportType.appendChild(cbItem);
    });
    comboboxSportType.setAttribute("value", default_sport_type);
    updateLayer(activitiesLayer, null, null, null, null, default_sport_type, null);

    // StravaId & initial lonlat
    if (queryParam_activity_strava_id != null) {
      filterActivitiesOnStravaId(queryParam_activity_strava_id);
      getActivityDetailByTokenAsync(sessionStorage.getItem("token"), queryParam_activity_strava_id, function (dummy, err, activityDetail) {
        if (err) {
          toast.innerHTML = "Activity not found";
          toast.show();
          setDateFilter("all", null, null, this);
        } else {
          if (activityDetail != null) {
            map.getView().setCenter(transform([activityDetail.lon, activityDetail.lat], 'EPSG:4326', 'EPSG:3857'));
            setDateFilter("custom", startOfDay(getDateFromUTCString(activityDetail.start_date_local)), endOfDay(getDateFromUTCString(activityDetail.start_date_local)), this);
          } else {
            toast.innerHTML = "Activity not found";
            toast.show();
            setDateFilter("all", null, null, this);
          }
        }
        showMap();
      }, null)
    } else {
      if (athleteDetail.lastActivity == null) {
        setDateFilter("today", null, null, this);
      } else {
        setDateFilter("custom", startOfDay(getDateFromUTCString(athleteDetail.lastActivity.start_date_local)), g_dMax, this);
        map.getView().setCenter(transform([athleteDetail.lastActivity.lon, athleteDetail.lastActivity.lat], 'EPSG:4326', 'EPSG:3857'));
      }
      showMap();
    }

  }, null)
}

function calcDates(filterType) {
  var theDates = {
    startDate: null,
    endDate: null
  }

  switch (filterType) {
    case "today":
      theDates.startDate = startOfDay(g_dMax);
      theDates.endDate = g_dMax;
      break;
    case "lastWeek":
      theDates.startDate = startOfDay(datePlusDays(g_dMax, -7));
      theDates.endDate = g_dMax;
      break;
    case "lastMonth":
      theDates.startDate = startOfDay(datePlusDays(g_dMax, -31)); // can be optimized
      theDates.endDate = g_dMax;
      break;
    case "lastYear":
      theDates.startDate = startOfDay(datePlusDays(g_dMax, -365)); // can be optimized
      theDates.endDate = g_dMax;
      break;
    case "all":
      theDates.startDate = g_dMin;
      theDates.endDate = g_dMax;
      break;
    default:
  }
  return theDates;

}

function setDateFilter(filterType, startDate, endDate, initiatingElement) {

  switch (filterType) {
    case "today":
    case "lastWeek":
    case "lastMonth":
    case "lastYear":
    case "all":
      var theDates = calcDates(filterType);
      startDate = theDates.startDate;
      endDate = theDates.endDate;
      break;
    case "custom":
      break;
    default:
  }

  if (initiatingElement != activitiesRangeSlider) {
    activitiesRangeSlider.setAttribute("start-value", (startDate.getTime() / 1000).toString());
    activitiesRangeSlider.setAttribute("end-value", (endDate.getTime() / 1000).toString());
  }

  if (initiatingElement != activitiesDateRangePicker) {
    activitiesDateRangePicker.setAttribute("value", formatDateTimeToNormal(startDate) + " - " + formatDateTimeToNormal(endDate));
  }

  buttonDateFilterToday.setAttribute("design", filterType == "today" ? "Emphasized" : "Default");
  buttonDateFilterLastWeek.setAttribute("design", filterType == "lastWeek" ? "Emphasized" : "Default");
  buttonDateFilterLastMonth.setAttribute("design", filterType == "lastMonth" ? "Emphasized" : "Default");
  buttonDateFilterLastYear.setAttribute("design", filterType == "lastYear" ? "Emphasized" : "Default");
  buttonDateFilterAll.setAttribute("design", filterType == "all" ? "Emphasized" : "Default");

  updateLayer(activitiesLayer, null, null, startDate, endDate, null, null);
}

function fitMapToFullscreen() {
  //console.log(window.innerWidth + " " + window.innerHeight + " " + document.getElementById('mapresize').getBoundingClientRect().top);

  document.getElementById('mapresize').style.width = window.innerWidth + "px";
  document.getElementById('mapresize').style.height = (window.innerHeight - document.getElementById('mapresize').getBoundingClientRect().top) + "px";
}

function findMyLocation() {
  stopFindMyLocation();
  //console.log("find my location started");
  geolocation.setTracking(true);
}

function stopFindMyLocation() {
  if (geolocation.getTracking()) {
    //console.log("find my location stopped");
    geolocation.setTracking(false);
    positionFeature.setGeometry(null);
    accuracyFeature.setGeometry(null);
  }
}



function uploadRoute(id) {
  if (id != "") {
    console.log("Uploading route " + id);
    popoverUploadRoute.close();

    var callbackFunctionPars = {
      id: id
    };
    var url = "/svc/uploadRoute?token=" + sessionStorage.getItem("token");
    var theJson = { id: id };
    postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
      if (err) {
        console.log(err);

      } else {
        if (theJson.error) {
          console.log(theJson.error);
          toast.innerHTML = theJson.error.message;
          toast.show();


        } else {
          //console.log(theJson);
          //console.log("Route " + callbackFunctionPars.id + " uploaded");
          //toast.innerHTML = "Route " + callbackFunctionPars.id + " uploaded";
          toast.innerHTML = theJson.msg;
          toast.show();
          hideSelectedRouteLayers();
          redrawLayer(routesLayer);
          
          updateSelectedRouteLayers(theJson.route_id);

        }
      }

    }, callbackFunctionPars);

  }

}

function busyIndicatorShow(positionElement) {
  positionElement.appendChild(busyIndicator);
  busyIndicator.active = true;
}

function busyIndicatorHide() {
  busyIndicator.active = false;
}

function uploadLastRoute() {
  console.log("Uploading last route");
  popoverUploadRoute.close();

  var url = "/svc/uploadLastRoute?token=" + sessionStorage.getItem("token");
  var theJson = { dummy: null };
  postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
    if (err) {
      console.log(err);

    } else {
      if (theJson.error) {
        console.log(theJson.error);
        toast.innerHTML = theJson.error.message;
        toast.show();


      } else {
        //console.log(theJson);
        //console.log("Last route uploaded");
        //toast.innerHTML = "Last route uploaded";
        toast.innerHTML = theJson.msg;
        toast.show();
        hideSelectedRouteLayers();
        redrawLayer(routesLayer);

        updateSelectedRouteLayers(theJson.route_id);

      }
    }

  }, null);


}

function createRoute() {
  popoverMenu.close();

  var callbackFunctionPars = {
  };
  var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
  var theJson = {
    action: "new"
  };
  postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
    if (err) {
      console.log(err);

    } else {
      if (theJson.error) {
        console.log(theJson.error);
        toast.innerHTML = theJson.error.message;
        toast.show();

      } else {
        console.log(theJson);
        editRoute(theJson.id, theJson.name);

      }
    }

  }, callbackFunctionPars);
}

function copyRoute(id) {
  if (id != "") {
    var callbackFunctionPars = {
      id: id
    };
    var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
    var theJson = {
      action: "copy",
      id: id
    };
    postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
      if (err) {
        console.log(err);

      } else {
        if (theJson.error) {
          console.log(theJson.error);
          toast.innerHTML = theJson.error.message;
          toast.show();

        } else {
          console.log(theJson);
          editRoute(theJson.id, theJson.name);

        }
      }

    }, callbackFunctionPars);
  }
}

function editRoute(id, name) {
  if (id != "") {
    inputEditRouteSaveName.setAttribute("value", name);
    filterRoutesOnId(id);
    //updateSelectedRouteLayers(id); // do not "select" because of bad performance surface layer
    updateSelectedRouteLayersOnlyDistance();
    updateLayerStyles(routesLayer, routeEditLayerStyle)
    isEditingRoute = true;
    editRouteId = id;
    e_map.style.cursor = "crosshair";

    editRouteUndos = [];
    liEditRouteUndo.style.display = "none";

  }
}

function editRoutePointerMove(coordinate, projectionCode) {
  //console.log(coordinate);
  if (isMovingRoutePoint) {

  } else {
    if (editRoutePointerMovePostAsyncBusy) {
      pointerMoveToProcess = {
        coordinate: coordinate,
        projectionCode: projectionCode
      };
    } else {
      editRoutePointerMovePostAsyncBusy = true;
      var callbackFunctionPars = {
        coordinate: coordinate
      };
      var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
      var theJson = {
        action: "infoPoint",
        id: editRouteId,
        coordinate: coordinate,
        projectionCode: projectionCode
      };
      postAsync(url, theJson, false, function (callbackFunctionPars, err, theJson) {
        editRoutePointerMovePostAsyncBusy = false;
        if (err) {
          console.log(err);

        } else {
          if (theJson.error) {
            console.log(theJson.error);
            toast.innerHTML = theJson.error.message;
            toast.show();
          } else {
            console.log(theJson);
            crpFeature.iOnLine = theJson.crp_i;
            crpFeature.routing_info = theJson.routing_info;
            if (theJson.c_coordinate_3857 != null) {
              var z = map.getView().getZoom();
              var offset = (z > 17 ? 5 : (z > 16 ? 10 : (z > 15 ? 15 : (z > 14 ? 20 : 25))));
              var offset_crp = (z > 17 ? 20 : (z > 16 ? 40 : (z > 15 ? 60 : (z > 14 ? 80 : 100))));
              //console.log("zoom: " + z + "; c distance: " + theJson.c_distance + " (" + offset + "); crp_distance: " + theJson.crp_distance + " (" + offset_crp + ")");
              if (theJson.c_distance < offset || theJson.crp_distance < offset_crp) {
                isAddingPoint = false;
                if (theJson.crp_distance < offset_crp) {
                  isRoutePointSelected = true;
                  crpFeature.setGeometry(new Point(theJson.crp_coordinate_3857));
                  cFeature.setGeometry(null);
                } else {
                  isRoutePointSelected = false;
                  cFeature.setGeometry(new Point(theJson.c_coordinate_3857));
                  crpFeature.setGeometry(null);
                }
                e_map.style.cursor = "pointer";
              } else {
                isAddingPoint = true;
                cFeature.setGeometry(null);
                crpFeature.setGeometry(null);
                e_map.style.cursor = "crosshair";
              }
            } else {
              isAddingPoint = true;
              cFeature.setGeometry(null);
              crpFeature.setGeometry(null);
              e_map.style.cursor = "crosshair";
            }
          }
        }

        if (pointerMoveToProcess != null) {
          var theCoordinate = pointerMoveToProcess.coordinate;
          var theProjectionCode = pointerMoveToProcess.projectionCode;
          pointerMoveToProcess = null;
          editRoutePointerMove(theCoordinate, theProjectionCode);
        }

      }, callbackFunctionPars);
    }
  }


}

function editRouteClickOrMoveEnd(coordinate, projectionCode, pixel) {
  editAnyRouteTimestamp = new Date().getTime();

  switch (projectionCode) {
    case "EPSG:3857":
      // coordinate in OSM projection
      break;

  }
  console.log("Click or move end route " + editRouteId + " (" + inputFilterRouteStravaId.getAttribute("value") + "): " + coordinate + " (" + projectionCode + ")");

  if (isAddingPoint) {

    var callbackFunctionPars = {
    };
    var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
    var theJson = {
      action: "addPoint",
      id: editRouteId,
      coordinate: coordinate,
      projectionCode: projectionCode,
      append: true
    };
    postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
      if (err) {
        console.log(err);

      } else {
        if (theJson.error) {
          console.log(theJson.error);
          toast.innerHTML = theJson.error.message;
          toast.show();

        } else {
          //console.log(theJson);

          editRouteUndos.push({ undoType: 'geom', geom_text: theJson.old_st_astext });
          liEditRouteUndo.style.display = "block"

          redrawRouteEditingLayers();
        }
      }

    }, callbackFunctionPars);

  } else {
    if (isMovingRoutePoint) {

      var crpCoordinate = crpFeature.getGeometry().getCoordinates();
      //console.log("Point to move: " + crpCoordinate);

      var callbackFunctionPars = {
      };
      var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
      var theJson = {
        action: "setPoint",
        id: editRouteId,
        i: crpFeature.iOnLine,
        coordinate: crpCoordinate,
        projectionCode: projectionCode
      };
      postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
        isMovingRoutePoint = false;
        if (err) {
          console.log(err);

        } else {
          if (theJson.error) {
            console.log(theJson.error);
            toast.innerHTML = theJson.error.message;
            toast.show();

          } else {
            //console.log(theJson);

            editRouteUndos.push({ undoType: 'geom', geom_text: theJson.old_st_astext });
            liEditRouteUndo.style.display = "block"

            redrawRouteEditingLayers();

            cFeature.setGeometry(null);
            crpFeature.setGeometry(null);
          }
        }

      }, callbackFunctionPars);

    } else {
      if (isRoutePointSelected) {

        if (crpFeature.iOnLine != 0) {
          popoverAnchor.style.position = "absolute";
          popoverAnchor.style.left = (pixel[0] + e_map.getBoundingClientRect().left) + "px";
          popoverAnchor.style.top = (pixel[1] + e_map.getBoundingClientRect().top) + "px";
  
          if (crpFeature.routing_info != null) {
            var routing_info = JSON.parse(crpFeature.routing_info);
            var routing_mode = routing_info.points[crpFeature.iOnLine].routing_mode;
            console.log(routing_mode);
            rbEditRoutePointRoutingModeA.checked = (routing_mode == 'A');
            rbEditRoutePointRoutingModeM.checked = (routing_mode == 'M');
            rbEditRoutePointRoutingModeG.checked = (routing_mode == 'G');
          }
  
          popoverEditRoutePoint.modal = true;
          popoverEditRoutePoint.showAt(popoverAnchor, true);
  
        }

      } else {
        console.log("Point to insert: " + cFeature.getGeometry().getCoordinates());

        var callbackFunctionPars = {
        };
        var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
        var theJson = {
          action: "addPoint",
          id: editRouteId,
          coordinate: cFeature.getGeometry().getCoordinates(),
          projectionCode: projectionCode,
          append: false
        };
        postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
          if (err) {
            console.log(err);

          } else {
            if (theJson.error) {
              console.log(theJson.error);
              toast.innerHTML = theJson.error.message;
              toast.show();

            } else {
              //console.log(theJson);

              editRouteUndos.push({ undoType: 'geom', geom_text: theJson.old_st_astext });
              liEditRouteUndo.style.display = "block"

              redrawRouteEditingLayers();
              crpFeature.setGeometry(cFeature.getGeometry());
              cFeature.setGeometry(null);

            }
          }

        }, callbackFunctionPars);

      }
    }
  }
}

function editRoutePointRoutingModeChanged() {
  popoverEditRoutePoint.close();

  var routing_mode = (rbEditRoutePointRoutingModeA.checked ? 'A' : rbEditRoutePointRoutingModeM.checked ? 'M' : 'G');
  var routing_info = JSON.parse(crpFeature.routing_info);
  routing_info.points[crpFeature.iOnLine].routing_mode = routing_mode;
  if (crpFeature.iOnLine > 0) {
    routing_info.points[crpFeature.iOnLine - 1].route_lonlats = null;
  }


  var callbackFunctionPars = {
  };
  var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
  var theJson = {
    action: "updateRoutingInfo",
    id: editRouteId,
    routing_info: JSON.stringify(routing_info)
  };


  postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
    if (err) {
      console.log(err);

    } else {
      if (theJson.error) {
        console.log(theJson.error);
        toast.innerHTML = theJson.error.message;
        toast.show();

      } else {
        console.log(theJson);

        editRouteUndos.push({ undoType: 'routing_info', routing_info: theJson.old_routing_info });
        liEditRouteUndo.style.display = "block"

        redrawRouteEditingLayers();

        cFeature.setGeometry(null);
        crpFeature.setGeometry(null);
      }
    }

  }, callbackFunctionPars);

}

/*
function editRoutePointToggleRoutingMode() {
  popoverEditRoutePoint.close();

  var callbackFunctionPars = {
  };
  var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
  var theJson = {
    action: "editPointToggleRoutingMode",
    id: editRouteId,
    i: crpFeature.iOnLine
  };
  postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
    if (err) {
      console.log(err);

    } else {
      if (theJson.error) {
        console.log(theJson.error);
        toast.innerHTML = theJson.error.message;
        toast.show();

      } else {
        console.log("Should register that the undo is a toggle as de old_st_astext is not changed...")
        console.log(theJson);

        editRouteUndos.push({ undoType: 'routing_info', routing_info: theJson.old_routing_info });
        liEditRouteUndo.style.display = "block"

        redrawRouteEditingLayers();

        cFeature.setGeometry(null);
        crpFeature.setGeometry(null);
      }
    }

  }, callbackFunctionPars);
}
*/
function editRoutePointCancel() {
  popoverEditRoutePoint.close();
}

function editRoutePointDelete() {
  popoverEditRoutePoint.close();

  var callbackFunctionPars = {
  };
  var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
  var theJson = {
    action: "removePoint",
    id: editRouteId,
    i: crpFeature.iOnLine
  };
  postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
    if (err) {
      console.log(err);

    } else {
      if (theJson.error) {
        console.log(theJson.error);
        toast.innerHTML = theJson.error.message;
        toast.show();

      } else {
        console.log(theJson);

        editRouteUndos.push({ undoType: 'geom', geom_text: theJson.old_st_astext });
        liEditRouteUndo.style.display = "block"

        redrawRouteEditingLayers();

        cFeature.setGeometry(null);
        crpFeature.setGeometry(null);
      }
    }

  }, callbackFunctionPars);
}

function editRouteUndo() {

  console.log(editRouteUndos[editRouteUndos.length - 1]);

  var callbackFunctionPars = {
  };
  var url;
  var theJson;
  switch (editRouteUndos[editRouteUndos.length - 1].undoType) {
    case "geom":
      url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
      theJson = {
        action: "updateGeomFromText",
        id: editRouteId,
        text: editRouteUndos[editRouteUndos.length - 1].geom_text
      };
      break;
    case "routing_info":
      url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
      theJson = {
        action: "updateRoutingInfo",
        id: editRouteId,
        routing_info: editRouteUndos[editRouteUndos.length - 1].routing_info
      };
      break;
  }


  postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
    if (err) {
      console.log(err);

    } else {
      if (theJson.error) {
        console.log(theJson.error);
        toast.innerHTML = theJson.error.message;
        toast.show();

      } else {
        console.log(theJson);

        var lastUndo = editRouteUndos.pop();
        if (editRouteUndos.length == 0) {
          liEditRouteUndo.style.display = "none";
        } else {

        }

        redrawRouteEditingLayers();
      }
    }

  }, callbackFunctionPars);


}

function redrawRouteEditingLayers() {
  redrawLayer(routesLayer);
  //redrawLayer(highlightRouteLayer);
  redrawLayer(distanceRouteLayer);
  //redrawLayer(surfaceRouteLayer);

}

function editRouteSaveUpdateName(name) {
  //console.log("Saving route");
  popoverEditRouteSave.close();
  popoverEditRoute.close();
  cFeature.setGeometry(null);

  var callbackFunctionPars = {
  };
  var url = "/svc/editRoute?token=" + sessionStorage.getItem("token");
  var theJson = {
    action: "updateName",
    id: editRouteId,
    name: name
  };
  postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {

    if (err) {
      console.log(err);

    } else {
      if (theJson.error) {
        console.log(theJson.error);
        toast.innerHTML = theJson.error.message;
        toast.show();

      } else {
        //console.log(theJson);
        toast.innerHTML = "Route saved";
        toast.show();

        isEditingRoute = false;
        inputFilterRouteId.setAttribute("value", "");
        inputFilterRouteStravaId.setAttribute("value", "");
        updateLayer(routesLayer, null, "", null, null, null, null);
        updateLayerStyles(routesLayer, routesLayerStyle)
        e_map.style.cursor = "default";

      }
    }

  }, callbackFunctionPars);


}

function findMyLocationExit() {
  popoverFindMyLocation.close();
  stopFindMyLocation();
}

function editRouteSave(name) {

  editRouteSaveUpdateName(name);

}

function deleteRoute(id, name) {
  if (id != "") {
    console.log("Deleting route " + id);
    popoverFeature.close();

    var callbackFunctionPars = {
      id: id,
      name: name
    };
    var url = "/svc/deleteRoute?token=" + sessionStorage.getItem("token");
    var theJson = { id: id };
    postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
      if (err) {
        console.log(err);

      } else {
        if (theJson.error) {
          console.log(theJson.error);
          toast.innerHTML = theJson.error.message;
          toast.show();


        } else {
          console.log("Route " + callbackFunctionPars.id + " deleted");
          toast.innerHTML = "'" + callbackFunctionPars.name + "' deleted";
          toast.show();

          hideSelectedRouteLayers();
          redrawLayer(routesLayer);

        }
      }

    }, callbackFunctionPars);

  }

}

function deleteActivity(id, name) {
  if (id != "") {
    console.log("Deleting activity " + id);
    popoverFeature.close();

    var callbackFunctionPars = {
      id: id,
      name: name
    };
    var url = "/svc/deleteActivity?token=" + sessionStorage.getItem("token");
    var theJson = { id: id };
    postAsync(url, theJson, true, function (callbackFunctionPars, err, theJson) {
      if (err) {
        console.log(err);

      } else {
        if (theJson.error) {
          console.log(theJson.error);
          toast.innerHTML = theJson.error.message;
          toast.show();


        } else {
          console.log("Activity " + callbackFunctionPars.id + " deleted");
          toast.innerHTML = "'" + callbackFunctionPars.name + "' deleted";
          toast.show();

          hideSelectedActivityLayers();
          redrawLayer(activitiesLayer);

        }
      }

    }, callbackFunctionPars);

  }

}

function redrawLayer(layer) {
  var source = layer.getSource();
  var params = source.getParams();
  params.t = new Date().getMilliseconds();
  source.updateParams(params);
}